import card from '../../Assets/images/visuels/echange/card.png';
import momo from '../../Assets/images/visuels/echange/momo.png';
import eth from '../../Assets/images/visuels/echange/eth.png';
import CryptoToMomo from '../../RouteComponents/clientDashboard/userExchange/CryptoToMomo';
import MomoToCrypto from '../../RouteComponents/clientDashboard/userExchange/MomoToCrypto';
import CarteToMomo from '../../RouteComponents/clientDashboard/userExchange/CarteToMomo';
import CarteToCrypto from '../../RouteComponents/clientDashboard/userExchange/CarteToCrypto';

export const ex_devices = [
  // {
  //   name: 'crypto',
  //   from: 'Crypto',
  //   to: 'Vers Momo',
  //   image: eth,
  //   formComponent: <CryptoToMomo />,
  // },
  {
    name: 'momo',
    from: 'MoMo',
    to: 'Vers Crypto',
    image: momo,
    formComponent: <MomoToCrypto />,
  },
  {
    name: 'carteM',
    from: 'Carte bancaire',
    to: 'Vers Momo',
    image: card,
    formComponent: <CarteToMomo />,
  },
  {
    name: 'carteC',
    from: 'Carte bancaire',
    to: 'Vers Crypto',
    image: card,
    formComponent: <CarteToCrypto />,
  },
];