import { useState } from "react";
import { useDispatch } from 'react-redux';
import Tableau from '../../../UiKIts/array/array';
import Button from "../../../UiKIts/button/button";
import { Tabs1 } from '../../../UiKIts/tabs/tabs';
import { setModal, setModalContent } from '../../../store/actions/modalActions';
import CustomSelect from '../../../UiKIts/form/select/custom';
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ChangeTransactionStatus, TransactionInfo } from "./modals";



function ExchangeHistoric({ props }) {
    const { user, datas, reloadTransactions, lang, withPage } = props;
    const [formValues, setFormValues] = useState({ filterBy: "", search: "" });
    const [maxDataVisible, setMaxDataVisible] = useState(10);
    const location = useLocation();
    const dispatch = useDispatch();

    const transTitleAndKeyTable = [
        { title: lang.arrayTitles.id, key: "transactionId" },
        { title: lang.arrayTitles.type, key: "type" },
        { title: lang.arrayTitles.amount, key: "amount" },
        { title: lang.arrayTitles.state, key: "status" },
        { title: "Date", key: "createdAt" },
    ]

    const tabsArray = [
        { name: "Tous", result: dispatchArray('Tous') },
        { name: lang.typeTransaction[0], result: dispatchArray(lang.typeTransaction[0]) },
        { name: lang.typeTransaction[1], result: dispatchArray(lang.typeTransaction[1]) },
        { name: lang.typeTransaction[2], result: dispatchArray(lang.typeTransaction[2]) },
        { name: lang.typeTransaction[3], result: dispatchArray(lang.typeTransaction[3]) },
    ]


    function showMoreTransaction() {
        // setLatestData(0,10)
        setMaxDataVisible((prev) => prev + 5)
    }
    function showLessTransaction() {
        // setLatestData(0,10)
        setMaxDataVisible((prev) => prev - 5)
    }

    async function deleteTransaction(data) {
        try {
            const makeDelete = await axios.delete("/transaction/" + data._id);
            if (makeDelete.data) {
                alert('Yess operation de suppression reussi')
            }
        } catch (error) {
            alert('Erreur dans la requète.....')
        }
    }
    async function updateTransaction(data) {
        dispatch(setModal(true));
        dispatch(setModalContent(<ChangeTransactionStatus currentData={data} reloadTransactions={reloadTransactions}
            dispatch={dispatch} />));
    }
    async function seeTransaction(data) {
        dispatch(setModal(true));
        dispatch(setModalContent(<TransactionInfo currentData={data} user={user} />));
    }
    function historicArrayActions() {
        if (user.role === "admin") {
            return [
                { makeAction: updateTransaction, type: "Changez le statut" },
                // { makeAction: deleteTransaction, type: "Supprimez" },
                { makeAction: seeTransaction, type: "Voir" },
            ];
        }
        return [
            { makeAction: seeTransaction, type: "Voir" },
        ];;

    }
    function dispatchArray(typeData) {
        let finalArray = [];
        if (typeData == "Tous") {
            finalArray = [...datas];
        } else if (["Crypto à Momo"].includes(typeData)) {
            finalArray = [...datas.filter((transaction) => transaction.type == "cryptoToMomo")]
        } else if (["Momo à Crypto"].includes(typeData)) {
            finalArray = [...datas.filter((transaction) => transaction.type == "momoToCrypto")]
        } else if (["Carte à Momo"].includes(typeData)) {
            finalArray = [...datas.filter((transaction) => transaction.type == "cardToMomo")]
        } else if (["Carte à Crypto"].includes(typeData)) {
            finalArray = [...datas.filter((transaction) => transaction.type == "cardToCrypto")]
        }
        finalArray = finalArray.slice(0, maxDataVisible)
        // setLatestData((finalArray.length - 1))
        const { filterBy, search } = formValues;

        if (filterBy && filterBy != "") {
            const copy = [...finalArray];
            let filtered = [];
            if (filterBy === "status_validated") {
                filtered = copy.filter(el => el.status === "validated")
            } else if (filterBy === "status_pending") {
                filtered = copy.filter(el => el.status === "pending")
            } else if (filterBy === "status_aborted") {
                filtered = copy.filter(el => el.status === "aborted")
            }
            finalArray = filtered;
        }

        if (search != "") {
            const copy = [...finalArray];
            let filtered = copy.filter(el => el.transactionId.includes(search))
            finalArray = filtered
        }

        const filterChoices = [
            { label: "Tout voir", value: "" },
            // { label: "Filter par date", value: "" },
            { label: "Filter par statut validé", value: "status_validated" },
            { label: "Filter par statut pending", value: "status_pending" },
            { label: "Filter par statut aborted", value: "status_aborted" },
        ]
        return <div className='transactions-list-search-and-filter'>
            <section className='tlsf-sf'>
                <input type="search" placeholder='Chercher un identifiant de transaction'
                    onChange={(e) => setFormValues((prev) => {
                        return { ...prev, search: e.target.value }
                    })} />
                <CustomSelect props={{
                    name: "filterBy", options: filterChoices, firstOption: false,
                    formValues, setFormValues,
                }} />
            </section>
            <section className='tlsf-table'>
                <Tableau props={{
                    titlesAndKeys: transTitleAndKeyTable,
                    datas: finalArray, tableActions: historicArrayActions()
                }} />
            </section>
            <div className="fullWidthBtn">
                {
                    (withPage && finalArray.length >= 10 && finalArray.length < datas.length)
                    && <button onClick={showMoreTransaction}>Voir plus</button>
                }
            </div>

            <div className="fullWidthBtn">
                {
                    (withPage && finalArray.length > 10)
                    && <button onClick={showLessTransaction}>Voir moins</button>
                }
            </div>
        </div>;
    }


    return (
        <div className='dh-foot'>
            {
                user.role === "admin" && !location.pathname.includes('historic') ? <section className="dhf-head">
                    <h3>{lang.title}</h3>

                    <div>
                        <Link to="/exchange/historic">
                            <Button props={{ content: "Voir toutes les transactions", color: 2, noBackground: true }} />
                        </Link>
                    </div>
                </section> : ""
            }
            {
                user.role === "client" ? <section className="dhf-head">
                    <h3>{lang.title}</h3>

                    <div>
                        <Link to="/exchange/transactions">
                            <Button props={{ content: "Effectuer une transaction", color: 1 }} />
                        </Link>
                    </div>
                </section> : ""
            }
            {
                datas.length > 0 ? <section>
                    <Tabs1 props={{ tabsArray }} />
                </section> :
                    <h4>Aucune transaction effectué</h4>
            }
        </div>
    )
}

export default ExchangeHistoric;