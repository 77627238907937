import React, { useEffect, useState } from 'react'
import { MdiChevronDown } from '../../icons/chevrons'
import './custom.css'
function CustomSelect({ props }) {
    const { label, name, options, firstOption,
        formValues, setFormValues,
        formField, setFormField, defaultValue } = props;
    const [toggleOptions, setToggleOptions] = useState(false);


    useEffect(() => {
        if (defaultValue) {
            setFormValues({ ...formValues, [name]: defaultValue })
        }
    }, []);
    function handleChangeSelect(obj) {
        setFormValues({ ...formValues, [name]: obj.value });
        setToggleOptions(false)
    }
    return (
        <div className='formFieldSelect'>
            {
                label && <label htmlFor="">{label}</label>
            }
            <section className='ffs-selecter' onClick={() => setToggleOptions(!toggleOptions)}>
                <div className='ffss-currentSelect' >
                    {
                        firstOption ? (formValues[name] || firstOption) : (formValues[name] || options[0].label)
                    }
                    <MdiChevronDown />
                </div>
            </section>
            {
                toggleOptions && <div className='ffs-options'>
                    {
                        options.map((opt, ind) => <span key={"custom key " + ind}
                            onClick={() => handleChangeSelect(opt)}>
                            {opt.label}
                        </span>)
                    }
                </div>
            }
        </div>
    )
}

export default CustomSelect
