export function authStatusSelector({ auth }) {
    return auth.authed
}

export function authUserSelector({ auth }) {
    return auth.user
}


// function authSelector({ auth }) {
//     return auth.user
// }