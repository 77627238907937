import React from 'react'
import { useEffect, useState } from 'react';
import Button from "../../../UiKIts/button/button";
import Dash_Banner from '../dashBanner';
import { Dash_Head_Cards } from '../dashCards';
import { Dash_Horizontal_Stats, Dash_Vertical_Tabs } from '../dashCharts';
import { Link } from 'react-router-dom';
import { SimpleImage } from '../../../UiKIts/image/image';



export function DashHead({ props }) {
    const { user, transactions, lang } = props;
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        transactions.length >= 0 && setIsLoading(false)
    }, [transactions]);


    function dispatchNotTransactionMessage() {
        if (user.role === "admin") {
            return <div className='noTransactions'>
                <h2>Aucune transaction de vos clients,  <br />
                    Vous ne pouvez pas visualisez vos statistiques </h2>
            </div>
        }
        return <div className='noTransactions'>
            <h2>Vous n'avez effectué aucune transaction </h2>
            <br />
            <Link to="/exchange/transactions">
                <Button props={{ content: "Démarrer une transaction", color: 1 }} />
            </Link>
        </div>
    }

    return (
        <div className='dh-head'>
            {
                !isLoading ? <>
                    {
                        transactions.length > 0 ? <>
                            <section className='dhh-left'>
                                {
                                    user.role === "user" ? <Dash_Banner lang={lang} />
                                        : <Dash_Horizontal_Stats transactions={transactions} />
                                }
                                <Dash_Head_Cards transactions={transactions} />
                            </section>
                            <Dash_Vertical_Tabs transactions={transactions} />
                        </> : dispatchNotTransactionMessage()
                    }
                </>
                    : <div>
                        <SimpleImage props={{ src: "section_loader.svg", alt: "My custom section logo" }} />
                    </div>
            }
        </div>
    )
}



