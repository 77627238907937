import React from 'react';
import Bitcoin from '../../Assets/images/visuels/devises/Bitcoin.png';
import BUSD from '../../Assets/images/visuels/devises/BUSD.png';
import USDT from '../../Assets/images/visuels/devises/USDT.jpg';
import Etherum from '../../Assets/images/visuels/devises/Etherum.webp';
import other from '../../Assets/images/visuels/devises/other.png';


import useCrypto from '../../Hooks/useCrypto';

function HomeDevises() {
  const { cryptos } = useCrypto();
  const allImgs = {
    "Bitcoin": Bitcoin,
    "BUSD": BUSD,
    "USDT": USDT,
    "Etherum": Etherum,
  }
  return (
    <section className='home-devises'>
      <div className='home-devices-title'>
        <h2>Devises Acceptées</h2>
      </div>
      <div className='devices'>
        {
          cryptos.length > 0 ? <>
            {
              cryptos.map((cd, ind) => <div className='card' key={"accepted devise currency" + ind}>
                {
                  allImgs[cd.currency] ? <img className='img-responsive' src={allImgs[cd.currency]} alt='Bi' />
                    : <img className='img-responsive' src={other} alt='Bi' />
                }
                <span>{cd.currency}</span>
              </div>)
            }
          </> : "..."
        }
      </div>
    </section>
  );
}

export default HomeDevises;
// 1000000@
// jtchihoundjro@gmail.com