import React, { useState } from 'react'
import { MdiChevronDown, MdiChevronRight } from '../icons/chevrons';
import "./dropdown.css"

function Dropdown({ props }) {
    const { toggler, childsArrays } = props;
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='dropdown'>
            <div className='d-toggler' onClick={() => setIsOpen(!isOpen)}>
                {toggler}
                <MdiChevronDown />
            </div>
            {
                isOpen && <div className='d-childs'>
                    {
                        childsArrays.map((ca, index) => <article key={"dropd down nb" + index}>
                            {ca.label}
                        </article>)
                    }
                </div>
            }
        </div>
    )
}

export default Dropdown
