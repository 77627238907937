const defaultValue = { token: null, user: null, authed: false };
// { token: localStorage.getItem("auth-token"), user, authed: localStorage.getItem("auth-status") }



export default (state = defaultValue, action) => {
    switch (action.type) {
        case 'SET_AUTH_TOKEN': {
            localStorage.setItem("auth-token", action.payload)
            return { ...state, token: action.payload }
        };
        case 'SET_AUTH_STATUS': {
            localStorage.setItem("auth-status", action.payload)
            return { ...state, authed: action.payload }
        };

        case "SET_USER_INFOS": {
            const user = action.payload;
            if (user == null) {
                localStorage.removeItem("auth-token")
                localStorage.removeItem("auth-status")
                return { token: null, user: null, authed: false };
            }
            return { ...state, user }
        }

        case "SET_LOGOUT": {
            localStorage.removeItem("auth-token")
            localStorage.removeItem("auth-status")
            return action.payload;
        }



        default:
            return state;
    }
}