import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Button from '../../../UiKIts/button/button';
import { Input } from '../../../UiKIts/form/input';
import { openFeda } from '../../../utils/fedapay';
import { doTransaction } from '../../../utils/transactionObj';
import CustomSelect from '../../../UiKIts/form/select/custom';
import { authUserSelector } from "../../../store/selectors/authSelectors";
import { useSelector } from "react-redux";
import useCrypto from '../../../Hooks/useCrypto';
import { transformDataToSelectOption } from '../../../utils/data';
import { PayWithFedapay } from '.';

function CarteToCrypto() {
  const navigate = useNavigate();
  const user = useSelector(authUserSelector);
  const [isBankCard, setIsBankCard] = useState(null);
  const { cryptos } = useCrypto();
  const devisesList = transformDataToSelectOption(cryptos, "currency", "currency");


  const [formValues, setFormValues] = useState({
    typeTransaction: 'cardToCrypto',
    typeCrypto: 'BITCOIN',
    idTrans: '',
    from: '',
    amount: '',
    linkWallet: '',
    user
  });

  return (
    <>
      <div className='ex-devices-form'>
        <PayWithFedapay props={{
          formValues, setFormValues, type: "cardToCrypto", setIsBankCard
        }} />
        {
          (() => {
            if (isBankCard && isBankCard === true) {
              return <div className='ex-form'>
                <form onSubmit={(e) => doTransaction(e, formValues, navigate)}>
                  <h4>Détails de la transaction</h4>
                  <div className='div-inputs'>
                    <Input
                      props={{
                        type: 'text', name: 'idTrans', ph: '...',
                        label: <>ID transaction crypto<span className='text-danger'>*</span></>,
                        formValues, setFormValues, readOnly: true,
                      }}
                    />
                    <Input
                      props={{
                        type: 'number', name: 'amount', ph: '...',
                        label: <>Montant<span className='text-danger'>*</span></>,
                        formValues, setFormValues, readOnly: true,
                      }}
                    />
                  </div>
                  <h4>Détails de votre crypto </h4>
                  <div className='div-inputs'>
                    <CustomSelect id="typeCrypto" props={{
                      label: <>Type de Devise Crypto<span className='text-danger'>*</span></>,
                      options: devisesList, name: "typeCrypto",
                      formValues, setFormValues
                    }} />
                    <Input
                      props={{
                        type: 'text', name: 'linkWallet', ph: 'Insérer votre lien walet',
                        label: <>Lien Walet <span className='text-danger'>*</span></>,
                        formValues, setFormValues,
                      }}
                    />
                  </div>
                  <div className='form-button'>
                    <Button
                      props={{
                        content: 'Envoyer la Transaction',
                        color: 1, type: 'submit',
                      }}
                    />
                  </div>
                </form>
              </div>
            } else if (isBankCard && isBankCard === false) {
              return <h4>Vous devez payez par carte bancaire</h4>
            } else {
              return null;
            }
          })()
        }
      </div>
    </>
  );
}

export default CarteToCrypto;
