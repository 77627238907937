import { toast } from 'react-toastify';

const FedaPay = window['FedaPay'];

export const openFeda = (formVals, setFormVals, myOperation = false, setIsBankCard = false) => {
  const fedaWindow = FedaPay.init({
    public_key: process.env.REACT_APP_FEDAPAY_KEY,
    transaction: {
      amount: formVals.amount,
      description: 'Faites le dépôt',
    },
    customer: {
      email: formVals.user.email,
      lastname: formVals.user.name,
      firstname: formVals.user.firstname,
      phone_number: {
        number: formVals.my_num,
      },
    },
    onComplete: ({ reason, transaction }) => {
      if (reason === FedaPay.DIALOG_DISMISSED) {
        toast.info('Vous avez fermé Fedapay');
      } else {
        if (setIsBankCard) {

          if (transaction.mode === "cybersource") {
            toast.info('Transaction effectuée');
            setFormVals({
              ...formVals,
              amount: transaction.amount,
              idTrans: transaction.id,
            });
            return setIsBankCard(true);
          }
          setIsBankCard(false)
          return toast.info('Ce paiement nest pas bancaire mais a aboutis');
        }
        myOperation && myOperation(true)
        toast.info('Transaction effectuée');
        setFormVals({
          ...formVals,
          from: transaction.reference,
          amount: transaction.amount,
          idTrans: transaction.id,
        });

      }
    },
  });

  try {
    fedaWindow.open();
  } catch (e) {
    toast.error(e.message);
    return;
  }
};
