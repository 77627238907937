import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../UiKIts/button/button'
import { SimpleImage } from '../../UiKIts/image/image'

function Dash_Banner({ lang }) {
    return (
        <div className='dhhl-banner'>
            <section className='dhhlb-text'>
                <h1>{lang.text}</h1>
            </section>
            <section className='dhhlb-img'>
                <SimpleImage props={{ src: "banners/admin.png", alt: "client dash img " }} />
            </section>
            <Link to="/exchange/transactions">
                <Button props={{ content: "Effectuer une transaction", color: 2 }} />
            </Link>
        </div>
    )
}

export default Dash_Banner
