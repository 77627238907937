import { SimpleImage } from '../../UiKIts/image/image'
import { mapComponents } from '../../utils/forComponents'
import { calcTotal } from '../../utils/stats';

export function Dash_Head_Cards({ transactions }) {
    const cArray = [
        <Dash_Exchanges_Count_Card props={{ type: "Crypto", typeImg: "crypto.png", total: calcTotal(transactions, "ToCrypto") }} />,
        <Dash_Exchanges_Count_Card props={{ type: "Momo", typeImg: "momo.png", total: calcTotal(transactions, "ToMomo") }} />,
        // <Dash_Exchanges_Count_Card props={{ type: "Carte", typeImg: "card.png" }} />
    ]
    return (
        <div className='cdh-totalExchangeText'>
            {
                mapComponents(cArray, "cdh-totalExchangeText div", "div", "cdhte_Card")
            }
        </div>
    )
}

function Dash_Exchanges_Count_Card({ props }) {
    const { type, typeImg, total } = props;
    return <>
        <SimpleImage props={{ src: "visuels/echange/withBg/" + typeImg, alt: "Type Transaction " + type }} />
        {/* <b>{type} Envoyé</b>
            <SimpleImage props={{ src: "visuels/echange/send.png", alt: "type transaction " + type + " set" }} />
     */}

        <section>
            <b>Total {type}</b>
            <p className='transaction-receiveColor'>
                <SimpleImage props={{ src: "visuels/echange/receive.png", alt: "type transaction " + type + " receive" }} />
                <span>{total}</span>
            </p>
        </section>
    </>
}