import axios from "axios";
import FormBtn from '../../../UiKIts/form/btn';
import { Input } from '../../../UiKIts/form/input';
import { setModal, setModalContent } from "../../../store/actions/modalActions";
import { useState } from "react";
import { toast } from "react-toastify"


export function showUpdateModal(dispatch, reloadCryptos, crypto, cryptoId) {
    dispatch(setModal(true))
    dispatch(setModalContent(<UpdateModal props={{ dispatch, reloadCryptos, crypto }} />))

}


function UpdateModal({ props }) {
    const { dispatch, reloadCryptos, crypto } = props;
    const [formValues, setFormValues] = useState({
        currency: crypto.currency, usd_price: crypto.usd_price,
        xof_price: crypto.xof_price
    })

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const upCRypto = await axios.put("/crypto/" + crypto._id, formValues)

            if (upCRypto.data) {
                reloadCryptos()
                dispatch(setModal(false))
                dispatch(setModalContent(''))
                toast.success('Modification enregistré')
            }
        } catch (error) {
            alert('erreur survenue')
            toast.error('Une erreur est survenue')
        }
    }
    return <form action="" style={{ width: "90%", paddingTop: 30, paddingBottom: 30 }}
        onSubmit={(e) => handleSubmit(e)}>
        <h2>Modifier la crypto</h2>
        <Input props={{
            name: "currency", label: "La devise", ph: "Btc,Usdt,etc....",
            formValues, setFormValues
        }} />
        <Input props={{
            name: "usd_price", label: "Le prix unitaire  USD(Dollar)", ph: "0$.....",
            formValues, setFormValues
        }} />
        <Input props={{
            name: "xof_price", label: "Le prix unitaire XOF(cfa)", ph: "0cfa.....",
            formValues, setFormValues
        }} />
        <FormBtn props={{ content: "Enregistrer la modification", btnIsActive: true }} />
    </form>
}

export default UpdateModal;