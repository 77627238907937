const enDashboardTrad = {
    dashboard: {
        clientHeadBanner: {
            text: `Welcome to your Customer area,
             here you can make your transactions
              while monitoring their evolution over time`,
            btnText: ""
        },
        clientAdminCountStats: {

        },
        clientMenu: [
            'Dashboard', 'Exchanges', 'History', 'My profile'
        ],
        adminMenu: [
            'Dashboard', 'Your Customers', 'Your Cryptos',
            'Your Aggregators', 'History', 'My profile'
        ],
        logoutBtnText: "Logout",
        exchange: {
            historic: {
                title: 'Recent Transactions',
                viewAllTransactionBtn: 'View all transactions',
                fitterText1: 'All',
                input1Placeholder: 'Find a user',
                input2Placeholder: 'View All',
                arrayTitles: {
                    id: 'Transaction number',
                    type: 'Transaction type',
                    amount: 'Amount',
                    state: 'status',
                },
                typeTransaction: [
                    "Crypto to Momo", "Momo to Crypto", "Card to Momo", "Card to Crypto"
                ],
                statusList: [
                    "pending", "aborted", "validated"
                ]
            },
        }
    },
}

export default enDashboardTrad;