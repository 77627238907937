import React from 'react'
import { useParams } from 'react-router'
import CD_Home from './home';
import UserExchange from './user-exchange';

function ClientDashboard() {
    const params = useParams();


    function dispatchComponent() {
        if (params.action == "transactions") {
            return "..."
        }
        return <CD_Home />
    }
    return (
        <div className='clientDashboard'>
            {
                dispatchComponent()
            }
        </div>
    )
}

export default ClientDashboard
