import axios from "axios";
import { setModal, setModalContent } from "../../../store/actions/modalActions";
import { ConfirmationModal } from "../../../UiKIts/modal/modal";


export function showDeleteModal(dispatch, reloadCryptos, crypto, cryptoId) {
    dispatch(setModal(true))
    dispatch(setModalContent(<DeleteModal props={{ dispatch, reloadCryptos, crypto }} />))

}


function DeleteModal({ props }) {
    const { dispatch, reloadCryptos, crypto } = props;
    async function handleDelete() {
        try {
            const deleteCRypto = await axios.delete("/crypto/" + crypto._id)

            if (deleteCRypto.data) {
                reloadCryptos()
                dispatch(setModal(false))
                dispatch(setModalContent(''))
            }
        } catch (error) {
            alert('erreur survenue')
        }
    }
    return <ConfirmationModal props={{
        title: "Suppression de crypto",
        text: "Voulez vous vraiment supprimez cette crypto ?",
        whenConfirm: handleDelete
    }} />
}

export default DeleteModal;