import React from 'react'
import { DashHead } from '../../RouteComponents/global/dash/layout'
import useTransaction from '../../Hooks/useTransaction'
import { authUserSelector } from '../../store/selectors/authSelectors'
import { useSelector } from 'react-redux'
import ExchangeHistoric from '../../RouteComponents/global/exchange/historic'
import langSelector from '../../store/selectors/langSelector'

function AD_Home() {
    const user = useSelector(authUserSelector)
    const lang = useSelector(langSelector).langs
    const { transactions, reloadTransactions } = useTransaction();

    return (
        <div className='dash-home'>
            <DashHead props={{ user, transactions }} />
            <ExchangeHistoric props={{
                user, datas: transactions,
                reloadTransactions, lang: lang.dashboard.exchange.historic
            }} />
        </div>
    )
}

export default AD_Home
