import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

function useTransaction(userId = false) {
    const [transactions, setTransactions] = useState([]);
    const [reload, setReload] = useState(false);


    async function gettransactions() {
        try {
            let req = null;
            if (userId) {
                req = await axios.get('/transaction?user=' + userId)
            } else {
                req = await axios.get('/transaction')
            }
            setTransactions(req.data.data)
        } catch (err) {
            console.log('crypto marche pas')
        }
    }

    useEffect(() => {
        gettransactions();
        reload && setReload(false);
    }, [reload])


    function reloadTransactions() {
        setReload(true)
    }


    return {
        transactions, reloadTransactions
    }
}

export default useTransaction;