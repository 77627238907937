import Crypto_Add from "./add"
import CryptoList from "./list"
import useCrypto from "../../../Hooks/useCrypto"
function CryptoManager() {
    const { cryptos, reloadCryptos } = useCrypto()

    return <div className='e-cryptos'>
        <h2>Gestionnaire de crypto</h2>
        <br />
        <Crypto_Add props={{ reloadCryptos }} />
        <CryptoList props={{ cryptos, reloadCryptos }} />
    </div>
}

export default CryptoManager;