import React, { useEffect, useState } from 'react';
import { ex_devices } from '../../../rawDatas/client-dashboard/ex-devices';
import { FaInfoCircle } from 'react-icons/fa';
import { openFeda } from '../../../utils/fedapay';
import { Input } from '../../../UiKIts/form/input';
import Button from '../../../UiKIts/button/button';
import { useLocation } from 'react-router';

function ExchangeUser() {
  const location = useLocation();
  const [curForm, setCurForm] = useState('momo');


  useEffect(() => {
    const slicedLocation = location.hash.slice(1)
    if (["momo", "carteM", "carteC"].includes(slicedLocation)) {
      setCurForm(slicedLocation)
    }

  }, [])

  const setCurrent = (event, curName) => {
    if (event.target.checked) {
      setCurForm(curName);
    } else {
      setCurForm('');
    }
  };

  return (
    <>
      <div className='exchange'>
        <div>
          <div>
            <h4>Les Types de transactions Acceptées</h4>
            <p className='text-muted'>
              Cliquez pour sélectionner le type de transaction dont vous
              souhaitez effectués
            </p>
          </div>
          <div className='ex-devices'>
            {ex_devices.map((device, index) => (
              <label
                htmlFor={device.name}
                key={'Device' + index}
                className='ex-one-device'
              >
                <div className='ex-input'>
                  <input
                    type={'radio'}
                    name={'device'}
                    value={device.name}
                    id={device.name}
                    checked={curForm == device.name}
                    onClick={(e) => setCurrent(e, device.name)}
                  />
                </div>
                <div className={'card ex-card ex-card-' + device.name}>
                  <img
                    className='img-resp'
                    src={device.image}
                    alt={'Ex-' + device.name}
                  />
                </div>
                <div>
                  <span>
                    <b>{device.from}</b>
                  </span>
                  <p className='text-muted'>{device.to}</p>
                </div>
              </label>
            ))}
          </div>
          <div className='ex-devices-content'>
            {ex_devices.map((devic, index) => (
              <div
                key={'Content' + index}
                className={
                  'form-content ' + (curForm === devic.name ? 'current' : '')
                }
              >
                {devic.formComponent}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}



export function PayWithFedapay({ props }) {
  const { formValues, setFormValues, setFedaIsGood, setIsBankCard, type } = props
  return <div className='ex-info'>
    <div className='exi-alert'>
      <FaInfoCircle size={40} />
      <p>
        Cliquez sur le bouton payer via FedaPay, faites la capture d’écran
        et renseignez les informations restant et envoyez la transaction
      </p>
    </div>
    <div className='transAmountAnNum'>
      <Input
        props={{
          type: 'number', name: 'amount', ph: 'Montant du dépôt',
          formValues, setFormValues,
        }}
      />
      {
        type == "momoToCrypto" && <Input
          props={{
            type: 'tel', name: 'my_num', ph: 'Mon numero',
            formValues, setFormValues,
          }}
        />
      }
    </div>
    <Button
      props={{
        content: 'Cliquer pour Payer Via FedaPay',
        color: 1,
        functionAfterClick: (e) =>
          openFeda(formValues, setFormValues, setFedaIsGood ? setFedaIsGood : false, setIsBankCard ? setIsBankCard : false),
        disabled:
          formValues.idTrans !== '' || formValues.amount === '',
      }}
    />
  </div>
}
export default ExchangeUser;
