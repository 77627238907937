import { useState, useEffect } from "react";
import axios from "axios";
import { setModal, setModalContent } from '../../../store/actions/modalActions';
import moment from "moment";
import { toast } from "react-toastify";


export function ChangeTransactionStatus({ currentData, reloadTransactions, dispatch }) {
    const statusType = ["pending", "aborted", "validated"];
    const [availableStatus, setAvailableStatus] = useState([]);
    const visibleStatus = {
        "aborted": "Avorté", "validated": "Validé", "pending": "En cours"
    }
    function availableStatusToTupdate() {
        const copyStatus = [...statusType];
        const filtered = copyStatus.filter(el => el != currentData.status)
        return setAvailableStatus(filtered)
    }

    useEffect(() => {
        availableStatusToTupdate();
    }, [currentData.status]);


    function dispatchStyleToStatus(as) {
        let myStyle = {};
        myStyle.color = "white"
        myStyle.backgroundColor = "var(--status-color-" + as + ")"

        return myStyle;
    }


    async function makeStatusUpdate(newStatus) {
        try {
            const makeUp = await axios.put("/transaction/" + currentData._id, {
                status: newStatus,
                userMail: currentData.userId.email
            });
            if (makeUp.data) {
                reloadTransactions();
                dispatch(setModal(false));
                dispatch(setModalContent(null));
                toast.success('Status modifié avec succès');
            }
        } catch (error) {
            toast.error('Erreur dans la requète.....');
        }
    }

    return <div className='chooseNewStatus'>
        <TransactionInfo currentData={currentData} user={{ role: "admin" }} />
        <h4>Quele status voulez vous attribuez a cette transaction ?</h4>
        <section>
            {
                availableStatus.map((as, inde) => <button key={"available status top up nb" + inde}
                    style={dispatchStyleToStatus(as)}
                    onClick={() => makeStatusUpdate(as)}>
                    {visibleStatus[as]}
                </button>)
            }
        </section>
    </div>
}


export function TransactionInfo({ currentData, user }) {
    function getOtherInfo() {
        const corresponds = {
            devise: "La Devise", fullName: "Nom et Prenom"
        }
        const arr = [];
        for (const key in currentData.otherInfo) {
            arr.push({ title: corresponds[key], value: currentData.otherInfo[key] })
        }
        return arr;
    }
    return <div className='chooseNewStatus'>
        {
            user && user.role == "admin" && <>
                <h3>1. Informations du client ayant fait la transaction</h3>
                <ul className={"customDataList"}>
                    {/* <li>
                        <span>Identifiant Utilisateur : </span>
                        <b>{currentData.userId._id}</b>
                    </li> */}
                    <li>
                        <span>Nom et Prénom : </span>
                        <b>{currentData.userId.firstname + " " + currentData.userId.name}</b>
                    </li>
                    <li>
                        <span>Mail : </span>
                        <b>{currentData.userId.email}</b>
                    </li>
                    {
                        currentData.userId.tel && <li>
                            <span>Télephone : </span>
                            <b>{currentData.userId.tel}</b>
                        </li>
                    }
                </ul>
            </>
        }
        <h3>{user && user.role === "admin" ? "2." : "1."}  Informations générales de la transaction</h3>
        <ul className={"customDataList"}>
            <li>
                <span>Identifiant : </span>
                <b>{currentData.transactionId}</b>
            </li>
            <li>
                <span>Date de création : </span>
                <b>{moment(currentData.createdAt).format("MMM Do YY")}</b>
            </li>
            <li>
                <span>Montant : </span>
                <b>{currentData.amount}</b>
            </li>
            <li>
                <span>Type : </span>
                <b>{currentData.type}</b>
            </li>
        </ul>
        <h3>1. Autres Informations de la transaction</h3>
        <ul className={"customDataList"}>
            <li >
                <span>Expediteur: </span>
                <b>{currentData.from}</b>
            </li>
            <li >
                <span>Destinataire : </span>
                <b>{currentData.to}</b>
            </li>
            {
                getOtherInfo().map((go, ind) => <li key={"other info nb" + ind}>
                    <span>{go.title} : </span>
                    <b>{go.value}</b>
                </li>)
            }
        </ul>
    </div>
}
