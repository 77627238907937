import { toast } from 'react-toastify';

export const treatResponse = (res) => {
  if (res.error) {
    toast.error(res.message);
  }

  return res.data ?? null;
};

export const treatAndDisplay = (res) => {
  if (res.error) {
    toast.error("Une erreur s'est produite");
  } else {
    toast.info('Operation effectué avec succès');
  }

  return res.data ?? null;
};

export function handleChange(e, formValues, setFormValues) {
  const name = e.target.name;
  const value = e.target.value;

  setFormValues({ ...formValues, [name]: value });
}