import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
import AuthWithGoogle from '../RouteComponents/auth/google';
import AuthLogin from '../RouteComponents/auth/login';
import AuthSignUp from '../RouteComponents/auth/signup';
import langSelector from '../store/selectors/langSelector';
import { SimpleImage } from '../UiKIts/image/image';

function Auth() {
    const params = useParams();
    const lang = useSelector(langSelector).langs

    const authViewObj = {
        signup: <AuthSignUp lang={lang.auth.signUp.right} />,
        login: <AuthLogin lang={lang.auth.signIn.right} />,
        withGoogle: <AuthWithGoogle />
    }
    function dispatchAuthComponentTitle() {
        if (params.typeAuth == "login")
            return lang.auth.signIn.right.title
        else if (params.typeAuth === "signup")
            return lang.auth.signUp.right.title
        return null;
    }
    if (!["login", "signup", "withGoogle"].includes(params.typeAuth)) {
        return <>Erreu 404</>
    }
    return (
        <div className='auth'>
            <div className={params.typeAuth == "login" ? 'auth-body' : "auth-full-body"}>
                <section className='ab-banner'>
                    <Link to="/">
                        <SimpleImage props={{ src: "logo_white.png", alt: "Logo auth white" }} />
                    </Link>
                    <p>{lang.auth.signIn.left.title}</p>
                </section>
                <section className='ab-titleAndform'>
                    <h2>{dispatchAuthComponentTitle()}</h2>
                    {
                        authViewObj[params.typeAuth]
                    }
                </section>
            </div>
        </div>
    )
}

export default Auth;
