import axios from 'axios';
import React from 'react'
import useAgregator from '../../Hooks/useAgregator';

function Agregators() {
    const { agregators, reloadAgregators } = useAgregator();

    async function handleAgregatorUpdate(agregator) {
        // if(agregator.active){
        try {
            const activeAgregator = await axios.put("/agregator/setActive/" + agregator._id);
            const filterNotActiveAgregator = agregators.filter(el => el._id != agregator._id);
            const disableAgregator = await axios.put("/agregator/setInactive/" + filterNotActiveAgregator[0]._id);
            reloadAgregators();
        } catch (error) {
            alert('Il y a une erreur')
        }
        // }
    }
    return (
        <div className='agregators'>
            <h1>Gestion des agregateurs</h1>
            <ul>
                {
                    agregators.map((ag, index) => <li key={"agregator nb" + index}>
                        <p>
                            {ag.name}
                        </p>
                        <button className={ag.active === true ? "agregatorActiv" : "agregatorInactive"}
                            onClick={() => handleAgregatorUpdate(ag)} >
                            {
                                ag.active === true ? "Cet agregateur est activé" : "Utiliser cet agregateur"
                            }
                        </button>
                    </li>)
                }
            </ul>
        </div>
    )
}

export default Agregators
