import React from 'react';
import ExchangeUser from '../../../RouteComponents/clientDashboard/userExchange';

function UserExchange() {
  return (
    <>
      <ExchangeUser />
    </>
  );
}

export default UserExchange;
