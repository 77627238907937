import React from 'react'
import { TablerLoader2 } from '../icons/loader'
import "./form.css"


function FormBtn({ props }) {
    const { content, loader, btnIsActive } = props
    return (
        <div className={'formBtn ' + (btnIsActive ? "" : " btnDisable ")}>
            <button disabled={!btnIsActive} >{
                loader && loader != null ? <TablerLoader2 /> : content
            }</button>
        </div>
    )
}

export default FormBtn
