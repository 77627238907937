import React from 'react'
import "./array.css"
import moment from "moment"

function dispatchStatusStyle(data, key) {
    let myStyle = {};
    if (key === "status") {
        myStyle.color = "var(--status-color-" + data[key] + ")"
    }
    return myStyle;
}


function Tableau({ props }) {
    const statusVisible = {
        "pending": "En cours",
        "aborted": "Annulé",
        "validated": "Validé",
    }
    const { titlesAndKeys, datas, tableActions } = props;
    return (
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    {
                        titlesAndKeys.map((tak, i) => <th key={"array title nb" + i}>
                            {
                                tak.title
                            }
                        </th>)
                    }
                    {
                        tableActions && tableActions.length > 0 && <th>Actions</th>
                    }
                </tr>
            </thead>

            <tbody>
                {
                    datas.length > 0 ?
                        datas.map((data, index) => <tr key={"array nb" + index}>
                            <td>{index + 1} </td>
                            {
                                titlesAndKeys.map((tak, k) => <td key={"array data key nb" + k}
                                    style={dispatchStatusStyle(data, tak.key)}>
                                    {
                                        (() => {
                                            if (tak.key === "createdAt") {
                                                return moment(data[tak.key]).format("MMM Do YY")
                                            } else if (tak.key === "status") {
                                                return statusVisible[data[tak.key]]
                                            } else if (tak.key === "activated") {
                                                return data[tak.key] === true ? "Activé" : "Non activé"
                                            }
                                            else {
                                                return data[tak.key]
                                            }
                                        })()
                                    }
                                </td>)
                            }
                            {
                                tableActions && tableActions.length > 0 && <td>
                                    {
                                        tableActions.map((action, i) => <section key={"array action nb" + i}>
                                            {
                                                action.cond ? <>
                                                    {
                                                        data[action.condKey] === action.condResult ? <button style={{ padding: "2px 4px" }} onClick={() => { action.makeAction(data) }}
                                                            className={action.class}>
                                                            {action.type}
                                                        </button> : null
                                                    }

                                                </> : <button style={{ padding: "2px 4px" }} onClick={() => { action.makeAction(data) }}
                                                    className={action.class}>
                                                    {action.type}
                                                </button>
                                            }
                                        </section>)
                                    }
                                </td>
                            }
                        </tr>)
                        : <td colSpan={5}>
                            <h4 style={{ marginTop: 20 }}>Aucune donnée repertoriée</h4>
                        </td>
                }
            </tbody>
        </table>
    )
}

export default Tableau
