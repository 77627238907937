import frHomeLang from "./fr/home"
import enHomeLang from "./en/home"
import { frFooterLang, enFooterLang } from "./footer.js"
import frAuthLang from "./fr/auth"
import enAuthLang from "./en/auth"
import frDashboardTrad from "./fr/dashboard"
import enDashboardTrad from "./en/dashboard"
import { enNavs, frNavs } from "./nav"
import { frOthers } from "./other"

export const langs = {
    fr: {
        ...frHomeLang,
        ...frFooterLang,
        ...frAuthLang,
        ...frDashboardTrad,
        ...frNavs,
        ...frOthers
    },
    en: {
        ...enHomeLang,
        ...enFooterLang,
        ...enAuthLang,
        ...enDashboardTrad,
        ...enNavs
    }
}