import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../UiKIts/button/button'

function AccountVerify({ props }) {
    const { actionParam } = props;
    const [isVerify, setIsVerify] = useState(false);
    const [load, setLoad] = useState(true);


    useEffect(() => {
        axios.put('/user/confirmEmail/' + actionParam)
            .then(res => {
                setIsVerify(true)
                setLoad(false)
            })
            .catch(err => setLoad(false))
    }, [])
    return (
        <div className='account-verify'>
            {
                !load ? (isVerify ? <h1>Compte verifié</h1> :
                    <h1>Compte non verifié,veuillez reessayer</h1>)
                    : "...."
            }

            <section style={{ display: "flex", gap: "10px" }}>
                <Link to="/">
                    <Button props={{ content: "Revenir a l'accueil", color: 1, noBackground: true }} />
                </Link>
                {
                    !load ? (isVerify ? <Link to="/auth/login">
                        <Button props={{ content: "Se connecter", color: 1 }} />
                    </Link> :
                        <Link to="/auth/signup">
                            <Button props={{ content: "S'inscrire", color: 1 }} />
                        </Link>)
                        : "..."
                }
            </section>
        </div>
    )
}

export default AccountVerify
