import Button from "../../UiKIts/button/button"
import google from '../../Assets/images/icons/google.svg';

export function AUthWithGoogleBtn({ lang }) {
    const googleAuthUrl = process.env.NODE_ENV === 'production' ? '/google/auth/google' : 'http://localhost:3006/google/auth/google';
    return <div className='abtaf-otherAuth'>
        <a href={googleAuthUrl} >
            <Button
                props={{
                    content: (
                        <span>
                            <img
                                src={google}
                                className='img-icon'
                                alt='Google'
                                width={15}
                            />
                            {lang.googleBtn}
                        </span>
                    ),
                    color: 1,
                    noBackground: true,
                }}
            />
        </a>
    </div>
}