import React from 'react'
import Exchange_Historic_Layout from '../../exchanges/historic'

function ClientTransactions({ clientId }) {
    return (
        <div>
            <h1>Liste des transactions du client</h1>
            <section>
                <Exchange_Historic_Layout user={
                    { role: "user", _id: clientId }
                } />
            </section>
        </div>
    )
}

export default ClientTransactions
