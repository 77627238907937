import { Routes, Route } from 'react-router-dom';
import './Assets/styles/default.css';
import './Assets/styles/default-luap.css';
import './Assets/styles/global.css';
import './Assets/styles/transaction.css';
import './Assets/styles/auth.css';
import './Assets/styles/admin.css';
import './Assets/styles/profil.css';
import './Assets/styles/exchanges.css';
import './Assets/styles/account.css';
import "./Assets/styles/dashboard/index.css"
import "./Assets/styles/dashboard/home.css"


import Modal from './UiKIts/modal/modal';
import { useLocation } from 'react-router';
import { routesArray } from './rawDatas/routes';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import sidebarSelector from './store/selectors/sidebarSelector';

// REACT TOASTIFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { pageMiddleware } from './middleware';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getTheUser, logout, setAuth, setAuthToken } from './store/actions/authActions';
import { authStatusSelector, authUserSelector } from './store/selectors/authSelectors';
import { useState } from 'react';
import { existOrReturnFalse, existOrReturnNull } from './utils/data';
import langSelector from './store/selectors/langSelector';

function App() {
  const location = useLocation();
  const sideBarOpen = useSelector(sidebarSelector);
  const authed = useSelector(authStatusSelector);
  const user = useSelector(authUserSelector);
  const lang = useSelector(langSelector).langs
  const dispatch = useDispatch();
  const [dashLoading, setDashLoading] = useState(true)


  function makeLogout() {
    dispatch(logout())
  }
  useEffect(() => {

    dispatch(setAuth(existOrReturnFalse(localStorage.getItem("auth-status"))))
    dispatch(setAuthToken(existOrReturnNull(localStorage.getItem("auth-token"))))
    dispatch(getTheUser())
  }, [])

  useEffect(() => {
    setDashLoading(false)
  }, [user])

  return (
    <>
      <Routes>
        {routesArray().map((rt, indx) => (
          <Route
            key={'route nmber ' + indx}
            path={rt.path}
            element={
              pageMiddleware(rt, authed, rt.comp,
                sideBarOpen, location,
                user, makeLogout,
                lang)
            }
          />
        ))}
      </Routes>
      <Modal />
      <ToastContainer autoClose={3000} position={toast.POSITION.TOP_RIGHT} />
    </>
  );
}

export default App;
