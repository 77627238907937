export const frNavs = {
    simpleNav: {
        links: [
            { label: "Accueil", link: "/" },
            { label: "A propos", link: "/#howItWorks" },
            { label: "Echange", link: "/exchange/transactions" }
        ]
    },
    dashboardNav: {
        clientMenu: [
            'Accueil', 'Tableau de bord', 'Echanges', 'Historique', 'Mon profil'
        ],
        adminMenu: [
            'Accueil', 'Tableau de bord', 'Vos Clients', 'Vos Cryptos'
            , 'Historique', 'Mon profil'
        ]
    }
}

export const enNavs = {
    simpleNav: {
        links: [
            { label: "Home", link: "/" },
            { label: "About", link: "/#howItWorks" },
            { label: "Exchange", link: "/exchange/transactions" }
        ]
    },
    adminNav: {

    }
}