import React, { useEffect, useState } from 'react';
import useCrypto from '../../Hooks/useCrypto';
import { Input } from '../../UiKIts/form/input';
import CustomSelect from '../../UiKIts/form/select/custom';
import { transformDataToSelectOption } from '../../utils/data';

function HomeTransactions({ lang }) {

  const { cryptos } = useCrypto();
  const [formValues, setFormValues] = useState({
    currency: "", currency_count: 1, currency_result_Type: "XOF",
    currency_result: ""
  })

  const currencyArray = transformDataToSelectOption(cryptos, 'currency', "currency");

  const currencyTypeArray = [
    { label: "XOF", value: "XOF" },
    { label: "USD", value: "USD" },
  ]

  useEffect(() => {
    if (formValues.currency_count != 0 && cryptos.length > 0) {
      const copy = [...cryptos];
      const filterCurrency = copy.filter((el) => el.currency === formValues.currency)
      setFormValues((prev) => {
        if (formValues.currency_result_Type == "XOF") {
          return { ...prev, currency_result: filterCurrency[0].xof_price * formValues.currency_count }
        }
        return { ...prev, currency_result: filterCurrency[0].usd_price * formValues.currency_count }
      })
    }
  }, [formValues.currency_count, formValues.currency, formValues.currency_result_Type])
  return (
    <section className='home-transactions'>
      <div>
        {lang.title1}
        <h2>{lang.title2}</h2>
        {lang.title3}
      </div>
      {
        currencyArray.length > 0 ? <section className='ht-fields'>
          <CustomSelect props={{
            name: "currency", options: currencyArray, formValues, setFormValues,
            defaultValue: currencyArray[0].value
          }} />
          <Input
            props={{
              type: 'text', name: 'currency_count', ph: '1',
              formValues, setFormValues
            }}
          />
          <CustomSelect props={{
            name: "currency_result_Type", options: currencyTypeArray,
            formValues, setFormValues
          }} />
          <Input
            props={{
              type: 'text', name: 'currency_result', ph: '1.000.000',
              formValues, setFormValues
            }}
          />
        </section> : "..."
      }
    </section >
  );
}

export default HomeTransactions;
