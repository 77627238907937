export const frOthers = {
    others: {
        formFields: {
            firstname: {
                ph: "Votre Nom", label: "Nom"
            },
            lastname: {
                ph: "Votre Prénom", label: "Prénom"
            },
            phone: {
                ph: "Votre Télephone", label: "Télephone"
            },
            selfiePic: {
                ph: "", label: "Photo(selfie) de vous avec votre pièce d'identité"
            },
            piecePic: {
                ph: "", label: "Photo de votre pièce d'identité"
            },
            password: {
                ph: "Votre Mot de passe", label: "Mot de passe"
            },
            confirmPassword: {
                ph: "Confirmer mot de passe", label: "Confirmer mot de passe"
            },
        },
    }
}
