export const fakeStat1 = [
    { name: "Envoyé", value: 40000 },
    { name: "Recu", value: 10000 },
]
export const fakeStat2 = [
    { name: "Envoyé", value: 20000 },
    { name: "Recu", value: 10000 },
]
export const fakeStat3 = [
    { name: "Envoyé", value: 3000 },
    { name: "Recu", value: 10000 },
]

export const fakeStats = {
    jours: {
        send: [
            { name: "Envoyé", value: 10000 },
            { name: "Attendu", value: 10000 },
        ],
        receive: [
            { name: "Recu", value: 1000 },
            { name: "Attendu", value: 10000 },
        ]
    },
    semaine: {
        send: [
            { name: "Envoyé", value: 80000 },
            { name: "Attendu", value: 10000 },
        ],
        receive: [
            { name: "Recu", value: 8000 },
            { name: "Attendu", value: 10000 },
        ]
    },
    mois: {
        send: [
            { name: "Envoyé", value: 40000 },
            { name: "Attendu", value: 10000 },
        ],
        receive: [
            { name: "Recu", value: 4000 },
            { name: "Attendu", value: 10000 },
        ]
    }
}