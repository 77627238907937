const defaultValue = { show: false, content: null };


export default (state = defaultValue, action) => {
    switch (action.type) {
        case 'SET_MODAL':
            return { ...state, show: action.payload }
        case 'SET_MODAL_CONTENT':
            return { ...state, content: action.payload }
        default:
            return state;
    }
}