import { langs } from "../../Lang";

const defaultValue = localStorage.getItem('sev-lang') ? {
    langs: langs[localStorage.getItem('sev-lang')], current: localStorage.getItem('sev-lang')
}
    : { current: "fr", langs: langs.fr }
    ;


export default (state = defaultValue, action) => {
    switch (action.type) {
        case 'SET_LANG':
            return {
                langs: langs[action.payload],
                current: action.payload
            }
        default:
            return state;
    }
}