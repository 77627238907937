import { useDispatch } from "react-redux";
import useClients from "../../../Hooks/useClients";
import { setModal, setModalContent } from "../../../store/actions/modalActions";
import { ClientDetail } from "./details";
import { ActiveClientAccount, DisactiveClientAccount } from "./account";
import Tableau from "../../../UiKIts/array/array";


function ClientManager() {
    const { clients, reloadClients } = useClients();
    const dispatch = useDispatch();
    const clientTableTitlesAndKeys = [
        // { title: "id", key: "_id" },
        { title: "Nom", key: "firstname" },
        { title: "Prénom", key: "name" },
        { title: "Mail", key: "email" },
        { title: "Statut", key: "activated" }
    ]


    function viewClient(user) {
        dispatch(setModal(true))
        dispatch(setModalContent(<ClientDetail data={user} />))
        return;
    }
    function activeClient(user) {
        dispatch(setModal(true))
        dispatch(setModalContent(<ActiveClientAccount dispatch={dispatch} data={user} reloadClients={reloadClients} />))
        return;
    }
    function disActiveClient(user) {
        dispatch(setModal(true))
        dispatch(setModalContent(<DisactiveClientAccount dispatch={dispatch} data={user} reloadClients={reloadClients} />))
        return;
    }


    return <>
        <h1>Page de gestion des clients</h1>
        <section>
            <Tableau props={{
                titlesAndKeys: clientTableTitlesAndKeys, datas: clients, tableActions: [
                    { makeAction: viewClient, type: "Details", class: "viewBtn" },
                    {
                        makeAction: activeClient, type: "Activer", class: "activateBtn",
                        cond: true, condKey: "activated", condResult: false
                    },
                    {
                        makeAction: disActiveClient, type: "Desactiver", class: "disactivateBtn",
                        cond: true, condKey: "activated", condResult: true
                    }
                ]
            }} />
        </section>
    </>
}

export default ClientManager;