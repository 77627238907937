import React, { useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

function HomeFaq({ lang }) {
  const [faqActive, setFaqActive] = useState('');

  return (
    <section className='home-faq'>
      <h2>Questions Fréquentes et Réponses</h2>
      <div className='faqs'>
        {lang.questionList.map((faq, index) => (
          <div key={'FAQ_' + index} className='faq'
            onClick={(e) => setFaqActive(faqActive == faq.question ? '' : faq.question)}
          >
            <div className={'question ' + (faqActive != "" && faq.question == faqActive ? "question-active" : "")}>
              <h4>{faq.question}</h4>
              {
                faqActive != "" && faq.question == faqActive ? <FaChevronDown /> : <FaChevronRight />
              }
            </div>
            <div className={'question-answer' + (faq.question == faqActive ? ' qa-visible' : '')}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default HomeFaq;
