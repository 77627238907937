import { combineReducers } from "redux";
import modalReducer from "./modalReducer";
import sidebarReducer from "./sidebarReducer";
import authReducer from "./authReducer";
import langReducer from "./langReducer";

export default combineReducers({
    modal: modalReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    lang: langReducer
})