import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../UiKIts/button/button';
import { Input } from '../../../UiKIts/form/input';
import { doTransaction } from '../../../utils/transactionObj';
import { authUserSelector } from "../../../store/selectors/authSelectors";
import { useSelector } from "react-redux";
import { PayWithFedapay } from '.';

function CarteToMomo() {
  const navigate = useNavigate();
  const user = useSelector(authUserSelector);
  const [isBankCard, setIsBankCard] = useState(false);

  const [formValues, setFormValues] = useState({
    typeTransaction: 'cardToMomo',
    typeCrypto: 'Bitcoin',
    idTrans: '',
    amount: '', numMomo: '',
    fullName: '',
    user
  });

  return (
    <>
      <div className='ex-devices-form'>
        <PayWithFedapay props={{
          formValues, setFormValues, type: "cardToMomo", setIsBankCard
        }} />
        {
          isBankCard && <div className='ex-form'>
            <form
              method='POST'
              onSubmit={(e) => doTransaction(e, formValues, navigate)}>
              <h4>Détails de la transaction</h4>
              <div className='div-inputs'>
                <Input
                  props={{
                    type: 'text', name: 'idTrans', ph: '...',
                    label: (
                      <>
                        ID transaction crypto
                        <span className='text-danger'>*</span>
                      </>
                    ),
                    formValues, setFormValues, readOnly: true,
                  }}
                />
                <Input
                  props={{
                    type: 'number', name: 'amount', ph: '...',
                    label: (
                      <>
                        Montant
                        <span className='text-danger'>*</span>
                      </>
                    ),
                    formValues, setFormValues, readOnly: true,
                  }}
                />
              </div>
              <h4>Détails de votre Momo </h4>
              <div className='div-inputs'>
                <Input
                  props={{
                    type: 'text',
                    name: 'numMomo',
                    ph: 'Insérer votre numero MoMo',
                    label: (
                      <>
                        N° Momo Bénéficiaire
                        <span className='text-danger'>*</span>
                      </>
                    ),
                    formValues,
                    setFormValues,
                  }}
                />
                <Input
                  props={{
                    type: 'text', name: 'fullName', ph: 'Insérer votre nom complet',
                    label: (
                      <>
                        Nom et Prénoms
                        <span className='text-danger'>*</span>
                      </>
                    ), formValues, setFormValues,
                  }}
                />
              </div>
              <div className='form-button'>
                <Button
                  props={{
                    content: 'Envoyer la Transaction',
                    color: 1,
                    type: 'submit',
                  }}
                />
              </div>
            </form>
          </div>
        }

      </div>
    </>
  );
}

export default CarteToMomo;
