import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    ResponsiveContainer
} from "recharts";
export default function Chart3({ data }) {
    return (
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <LineChart
                chart
                style={{ left: 0, backgroundColor: "white" }}
                data={data}>
                <XAxis dataKey="month" />
                <YAxis type="number" tick={{ fontSize: 12, width: 250 }} />
                <Line
                    type="monotone"
                    dataKey="receive"
                    stroke="#05C0E9"
                    strokeWidth={5}
                    activeDot={{ r: 8 }}

                />
                <Line type="monotone" dataKey="set" stroke="#A10BE8" strokeWidth={5} />
            </LineChart>
        </ResponsiveContainer>
    );
}
