import { hasMaxSize, hasMinSize, isLetterAndNumber, isMail } from "./verificator";
const verificationObj = {
    "isMail": { func: isMail, text: "Mail invalide" },
    "isLetterAndNumber": { func: isLetterAndNumber, text: "Au moins un caractère alphabetique et au moins un numerique" },
    "hasMinSize": { func: hasMinSize, text: "Minimum " },
    "hasMaxSize": { func: hasMaxSize, text: "Maximum " }

}

export function errorToggler(fieldValue, fieldName, fieldVerification, errors, setErrors) {
    const fieldErrorIndex = errors.findIndex((el) => el.name === fieldName);
    const copyErrors = [...errors];
    if (verificationObj[fieldVerification].func(fieldValue)) {
        fieldErrorIndex >= 0 && copyErrors.splice(fieldErrorIndex, 1)
    } else {
        fieldErrorIndex < 0 && copyErrors.push({ name: fieldName, text: verificationObj[fieldVerification].text });
    }
    return setErrors(copyErrors);
}


export function sizeErrorToggler(fieldValue, fieldName, sizeVerification, minOrmax, errors, setErrors) {
    const fieldErrorIndex = errors.findIndex((el) => el.name === 'size');
    const copyErrors = [...errors];
    if (verificationObj[sizeVerification].func(fieldValue, minOrmax)) {
        fieldErrorIndex >= 0 && copyErrors.splice(fieldErrorIndex, 1)
    } else {
        fieldErrorIndex < 0 && copyErrors.push({ name: 'size', text: verificationObj[sizeVerification].text + " " + minOrmax + " caractères" });
    }
    setErrors(copyErrors);
    return copyErrors;
}
export function handleChange(e, dataSetterType, dataSetter, currentData = false) {
    const name = e.target.name;
    const value = e.target.value;

    if (dataSetterType == "obj")
        dataSetter({ ...currentData, [name]: value })
    else
        dataSetter(value)
}