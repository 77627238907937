import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../UiKIts/button/button';
import { Input } from '../../../UiKIts/form/input';
import { doTransaction } from '../../../utils/transactionObj';
import CustomSelect from '../../../UiKIts/form/select/custom';
import { authUserSelector } from "../../../store/selectors/authSelectors";
import { useSelector } from "react-redux";
import { PayWithFedapay } from '.';
import useCrypto from '../../../Hooks/useCrypto';
import { transformDataToSelectOption } from '../../../utils/data';

function MomoToCrypto() {
  const navigate = useNavigate();
  const user = useSelector(authUserSelector);
  const [fedaIsGood, setFedaIsGood] = useState(false);
  const { cryptos } = useCrypto();
  const devisesList = transformDataToSelectOption(cryptos, "currency", "currency");

  const [formValues, setFormValues] = useState({
    typeTransaction: 'momoToCrypto',
    typeCrypto: 'Bitcoin',
    idTrans: '',
    amount: '', my_num: "",
    numMomo: '',
    linkWallet: '',
    user
  });

  return (
    <>
      <div className='ex-devices-form'>
        {
          !fedaIsGood && <PayWithFedapay props={{
            formValues, setFormValues, setFedaIsGood, type: "momoToCrypto"
          }} />
        }
        {
          fedaIsGood && <>
            <h4 style={{ backgroundColor: "red", color: "white", padding: "20px", marginBottom: 20, borderRadius: 10 }}>
              Veuillez emplir ce formulaire pour achever votre operation</h4>
            <div className='ex-form'>
              <form onSubmit={(e) => doTransaction(e, formValues, navigate)}>
                {/* <h4>Faites le dépôt Momo</h4> */}

                <h4>Détails de la transaction</h4>
                <div className='div-inputs'>
                  <Input
                    props={{
                      type: 'text', name: 'idTrans', ph: '...',
                      label: (
                        <>
                          ID transaction crypto
                          <span className='text-danger'>*</span>
                        </>
                      ),
                      formValues, setFormValues, readOnly: true,
                    }}
                  />
                  <Input
                    props={{
                      type: 'number', name: 'amount', ph: '...',
                      label: (
                        <>
                          Montant
                          <span className='text-danger'>*</span>
                        </>
                      ),
                      formValues, setFormValues, readOnly: true,
                    }}
                  />
                </div>
                <h4>Détails de votre crypto </h4>
                <div className='div-inputs'>
                  <div className='select-input'>
                    <CustomSelect id="typeCrypto" props={{
                      label: <label>
                        Type de Devise Crypto<span className='text-danger'>*</span>
                      </label>,
                      options: devisesList, name: "typeCrypto",
                      formValues, setFormValues
                    }} />
                  </div>
                  <Input
                    props={{
                      type: 'text', name: 'linkWallet', ph: 'Insérer votre lien walet',
                      label: (
                        <>
                          Lien Walet
                          <span className='text-danger'>*</span>
                        </>
                      ),
                      formValues, setFormValues,
                    }}
                  />
                </div>
                <div className='form-button'>
                  <Button props={{ content: 'Envoyer la Transaction', color: 1, type: 'submit' }} />
                </div>
              </form>
            </div>
          </>
        }
      </div>
    </>
  );
}




export default MomoToCrypto;
