import React from 'react'
import useTransaction from '../../../Hooks/useTransaction'
import { SimpleImage } from '../../../UiKIts/image/image';
import ExchangeHistoric from '../../global/exchange/historic';
import langSelector from '../../../store/selectors/langSelector';
import { useSelector } from 'react-redux';


function Exchange_Historic_Layout({ user }) {
    const { transactions, reloadTransactions } = useTransaction(user.role == "admin" ? false : user._id);
    const lang = useSelector(langSelector).langs
    return (
        <div>
            <h1>Historique des transactions</h1>
            {
                transactions ? <>
                    {
                        transactions.length > 0 ? <ExchangeHistoric props={{
                            user, datas: transactions,
                            reloadTransactions, lang: lang.dashboard.exchange.historic,
                            withPage: true
                        }} />
                            : "Aucune donnée dans l'historique"
                    }

                </> : <SimpleImage props={{ src: "section_loader.svg", alt: "My section loader" }} />
            }
        </div>
    )
}

export default Exchange_Historic_Layout
