import React from 'react'

function Politique() {
    return (
        <div className='politic'>
            <h2>Politique de confidentialité </h2>
            <p>
                Ce présent site web est détenu par l’entreprise Sevenche, qui est un contrôleur de données de vos informations personnelles.
                Cette politique de confidentialité présente la manière dont nous traitons les informations collectées
                sur la plateforme et fournit également les raisons pour lesquelles nous devons collecter certaines données
                personnelles vous concernant. Par conséquent, prenez le temps de la lire pour en prendre connaissance
                avant d'utiliser le site.
                Nous prenons soin de vos données personnelles et nous nous engageons à en garantir la confidentialité
                et la sécurité.
            </p>

            <p>
                <b>Quelles sont les informations personnelles que nous collectons ?</b>
                <br /><br />
                Lorsque vous visitez la plateforme Sevexchange, nous recueillons automatiquement certaines informations sur votre
                appareil, par exemple les informations sur votre navigateur web, votre adresse IP et certains des cookies installés
                sur votre appareil. En plus, nous recueillons les données sur les termes de recherche qui vous ont orienté, les pages
                web, et sur la manière dont vous interagissez avec le site. Ces différentes informations collectées automatiquement sont
                désignées par : informations sur les appareils.
                Ensuite, il y a aussi les données personnelles que vous nous fournissez lors de votre inscription, c'est-à-dire nom,
                prénoms, adresse e-mail et d’autres informations relatives aux moyens de paiement que vous renseignez dans le cadre de
                la réussite de l’exécution du contrat.

            </p>


            <p>
                <b>Pourquoi traitons-nous vos données ?</b>
                <br /><br />
                Notre priorité absolue est la sécurité des données des clients et, à ce titre, nous ne pouvons traiter que
                des données minimales sur les utilisateurs, uniquement dans la mesure où cela est absolument nécessaire pour
                maintenir le site web. Les informations collectées automatiquement sont utilisées exclusivement pour identifier
                les cas potentiels d'abus et établir des informations statistiques concernant l'utilisation du site web.
                Ces dernières ne sont pas autrement agrégées de manière à identifier un utilisateur particulier du système.
                Vous pouvez parcourir la plateforme sans nous dire qui vous êtes ni révéler d'informations, par lesquelles
                quelqu'un pourrait vous identifier comme un individu spécifique et identifiable. Toutefois, si vous souhaitez
                bénéficier de certaines de nos fonctionnalités, vous pouvez nous fournir vos données personnelles, telles que : e-mail,
                nom, prénoms, ville de résidence et numéro de téléphone.
                Pour d’autres informations relatives aux données obligatoires à fournir pour utiliser le site, veuillez nous contacter
                via sevexhange@support.com.
            </p>

            <p>
                <b>Liens vers d'autres sites web</b>
                <br /><br />
                Notre site peut contenir des liens vers d'autres sites web qui ne sont pas détenus ou contrôlés par nous.
                Sachez que nous ne sommes pas responsables de ces autres sites web ou des pratiques de confidentialité des tiers.
                Nous vous encourageons à être attentif lorsque vous quittez notre plateforme et à lire les déclarations de
                confidentialité de chaque site web susceptible de collecter des informations personnelles.
            </p>

            <p>
                <b>Sécurité de l'information</b>
                <br /><br />

                Nous sécurisons les informations que vous fournissez sur des serveurs informatiques dans un environnement contrôlé et sécurisé,
                protégé contre tout accès, utilisation ou divulgation non autorisés. Nous conservons des garanties administratives,
                techniques et physiques raisonnables pour nous protéger contre tout accès, utilisation, modification et divulgation
                non autorisés des données personnelles sous son contrôle et sa garde. Toutefois,
                aucune transmission de données sur Internet ou sur un réseau sans fil ne peut être garantie.
            </p>

            <p>
                <b>Divulgation légale</b>
                <br /><br />
                Nous ne partageons aucune informations personnelles à une tierce personne, sauf dans les cas exceptionnels. En effet,
                nous divulguerons toute information que nous collectons, utilisons ou recevons si la loi l'exige ou l'autorise.
                Par exemple, pour nous conformer à une citation à comparaître ou à une procédure judiciaire similaire, et lorsque
                nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre sécurité ou celle d'autrui,
                enquêter sur une fraude ou répondre à une demande du gouvernement.
            </p>

            <p>
                <b>Informations de contact</b>
                <br /><br />
                Si vous souhaitez nous contacter pour comprendre davantage la présente politique ou si vous souhaitez nous contacter
                concernant toute question relative aux droits individuels et à vos informations personnelles, vous pouvez envoyer
                un courriel à sevexhange@support.com.
            </p>
        </div>
    )
}

export default Politique;

