import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const data = [
  { name: "Group A", value: 500 },
  { name: "Group A", value: 1000 },

];

function Chart1({ props }) {
  const { color, data } = props;
  // const { color } = props;

  const COLORS = [color, "#eee"];
  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      <PieChart >
        <Pie
          data={data}
          cx={"50%"}
          cy={"50%"}
          innerRadius={20}
          outerRadius={30}
          fill={color}
          paddingAngle={10}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>


    </ResponsiveContainer>
  );
}

export default Chart1
