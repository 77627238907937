import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { setModal, setModalContent } from '../../../store/actions/modalActions';
import Button from '../../../UiKIts/button/button'
import FormBtn from '../../../UiKIts/form/btn';
import { Input } from '../../../UiKIts/form/input';
import { toast } from "react-toastify"

function Crypto_Add({ props }) {
    const dispatch = useDispatch();
    const { reloadCryptos } = props;
    function showAddModal() {
        dispatch(setModal(true))
        dispatch(setModalContent(<AddModal props={{ dispatch, reloadCryptos }} />))

    }
    return (
        <>
            <Button props={{
                content: "Ajouter une crypto", color: 1,
                functionAfterClick: showAddModal
            }} />
            <br />
            <br />
        </>
    )
}


function AddModal({ props }) {
    const { dispatch, reloadCryptos } = props;
    const [formValues, setFormValues] = useState({ currency: "", usd_price: "", xof_price: "" })

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const addCRypto = await axios.post("/crypto", formValues)

            if (addCRypto.data) {
                reloadCryptos()
                dispatch(setModal(false))
                dispatch(setModalContent(''))
                toast.success('Crypto ajouté')
            }
        } catch (error) {
            toast.success('Une erreur est- survenue')
        }
    }
    return <form action="" style={{ width: "90%", paddingTop: 30, paddingBottom: 30 }}
        onSubmit={(e) => handleSubmit(e)}>
        <h2>Ajoutez une crypto</h2>
        <Input props={{
            name: "currency", label: "La devise", ph: "Btc,Usdt,etc....",
            formValues, setFormValues
        }} />
        <Input props={{
            name: "usd_price", label: "Le prix unitaire  USD(Dollar)", ph: "0$.....",
            formValues, setFormValues
        }} />
        <Input props={{
            name: "xof_price", label: "Le prix unitaire XOF(cfa)", ph: "0cfa.....",
            formValues, setFormValues
        }} />
        {/* usd_price, xof_price */}
        <FormBtn props={{ content: "Ajouter la devise", btnIsActive: true }} />
    </form>
}
export default Crypto_Add
