import React from 'react'
import { useDispatch } from 'react-redux';
import Tableau from '../../../UiKIts/array/array'
import { showDeleteModal } from './delete';
import { showUpdateModal } from './update';


function CryptoList({ props }) {
    const { cryptos, reloadCryptos } = props;
    const dispatch = useDispatch();
    const titlesAndKeys = [
        { title: "Devise", key: "currency" },
        { title: "Prix USD", key: "usd_price" },
        { title: "Prix XOF", key: "xof_price" },
    ];


    function updateCryptoBtn(crypto) {
        return showUpdateModal(dispatch, reloadCryptos, crypto, crypto._id);
    }

    function deleteCryptoBtn(crypto) {
        return showDeleteModal(dispatch, reloadCryptos, crypto, crypto._id);
    }
    const tableActions = [
        { makeAction: updateCryptoBtn, type: "Modifier", class: "upBtn" },
        { makeAction: deleteCryptoBtn, type: "Supprimer", class: "deleteBtn" },
    ]
    return (
        <div className='ec-list'>
            <h3>Liste des crypto  </h3>
            <br />
            <section>
                <Tableau props={{
                    titlesAndKeys,
                    datas: cryptos, tableActions
                }} />
            </section>
        </div>
    )
}

export default CryptoList
