import React, { useEffect, useState } from 'react'
import { MdiMagnify } from '../icons/form';
import { MdiEmail } from '../icons/mail';
import "./form.css"
import { handleChange, errorToggler } from './formUtils/dataManager';

export function Input({ props }) {
    const { type, name, ph, label, withIcon, iconPosition,
        formValues, setFormValues,
        formField, setFormField,
        fieldVerification } = props;
    const [errors, setErrors] = useState([]);
    const [isError, setIsError] = useState(false);

    function dispatchInputIcon() {
        if (type === "mail")
            return <MdiEmail style={{ [iconPosition ? iconPosition : "right"]: "10px" }} />
    }

    useEffect(() => {
        if (errors.length === 0) {
            setIsError(false)
        } else {
            setIsError(true)
        }
    }, [errors])


    useEffect(() => {
        if ((formValues && formValues[name] != "") || (formField && formField != "s")) {
            if (fieldVerification) {
                if (formValues) {
                    return errorToggler(formValues[name], name, fieldVerification, errors, setErrors)
                }
                errorToggler(formField, name, fieldVerification, errors, setErrors)
            }
        }
    }, [formValues[name] || formField])

    return (
        <div className='formField'>
            <label htmlFor="">{label}</label>
            <section>
                <input className={isError ? "isError" : ""} name={name} type={type} placeholder={ph}
                    onChange={(event) => handleChange(event,
                        formValues ? "obj" : "string",
                        formValues ? setFormValues : setFormField,
                        formValues && formValues)}
                    value={formValues ? formValues[name] : formField} />
                {
                    withIcon && dispatchInputIcon()
                }
            </section>
            <article className='isErrorText'>
                {/* {isError && errors.filter((err => err.name == name))[0].text} */}
                {
                    isError && errors.filter((err => err.name === name)).map((er, ind) => <span key={"error txt" + name + ind}>
                        {
                            er.text + ','
                        }
                    </span>)
                }
            </article>
        </div>
    )
}


export function InputSearch({ props }) {
    const { ph, label, withIcon, iconPosition } = props;

    return (
        <div className='formFieldSearch'>
            <section>
                <input type="search" placeholder={ph} />
                {
                    withIcon && <MdiMagnify style={{ [iconPosition ? iconPosition : "right"]: "10px" }} />
                }
            </section>
        </div>
    )
}
