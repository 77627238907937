export function existOrReturnFalse(data) {
    return data ? data : false;
}
export function existOrReturnNull(data) {
    return data ? data : null;

}
export function transformDataToSelectOption(data, labelKey, valueKey) {
    const newArray = [];
    for (let index = 0; index < data.length; index++) {
        newArray.push({
            label: data[index][labelKey],
            value: data[index][valueKey],
        })
    }
    return newArray;
}
