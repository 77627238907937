export function setModal(modalState) {
    return {
        type: "SET_MODAL",
        payload: modalState
    }
}

export function setModalContent(modalContent) {
    return {
        type: "SET_MODAL_CONTENT",
        payload: modalContent
    }
}