import React from 'react'
import { Input } from '../UiKIts/form/input'
import { InputPassword, InputPasswordConfirmation } from '../UiKIts/form/password'
import FormBtn from '../UiKIts/form/btn'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { authUserSelector } from '../store/selectors/authSelectors'
import { toast } from "react-toastify"
import axios from 'axios'
import { OnStageMessage } from './ClientDashboard/home'
import langSelector from '../store/selectors/langSelector'

function Profil() {

    const authInfos = useSelector(authUserSelector);
    const [formValues, setFormValues] = useState({
        name: authInfos?.name, firstname: authInfos?.firstname, email: authInfos?.email,
        phone: authInfos?.phone,
        password: "", confirm_password: "",
        selfie_pic: "", piece_pic: ""
    });
    const [fileIsUploaded, setFIU] = useState(authInfos.selfie_pic && authInfos.piece_pic &&
        authInfos.selfie_pic !== "" && authInfos.piece_pic !== "")
    const lang = useSelector(langSelector).langs;
    const profilLang = lang.dashboard.profil;
    const fieldsLang = lang.others.formFields;

    function handleFileChange(e) {
        const name = e.target.name;
        const value = e.target.files[0];
        setFormValues({ ...formValues, [name]: value })
    }
    async function handleSubmit(e) {
        e.preventDefault();
        try {
            let formData = {};
            if (authInfos.activated) {
                if (formValues.password != "" && formValues.confirm_password != "") {
                    formData = {
                        name: formValues.name, firstname: formValues.firstname, email: formValues.email,
                        phone: formValues.phone,
                        password: formValues.password, confirm_password: formValues.confirm_password,
                    }
                } else {
                    formData = {
                        name: formValues.name, firstname: formValues.firstname, email: formValues.email,
                        phone: formValues.phone
                    }
                }
                const makeUp = await axios.put('/user/' + authInfos._id, formData);
                if (makeUp.data) {
                    toast.success('Modification enregistré')
                }
            } else {
                formData = new FormData();
                formData.append("id", authInfos._id)
                formData.append("uploadDate", Date.now())
                formData.append("selfie_pic", formValues.selfie_pic)
                formData.append("piece_pic", formValues.piece_pic)
                const makeUpFile = await axios.put('/user/up-pics/' + authInfos._id, formData);
                if (makeUpFile.data) {
                    toast.success('Modification enregistré')
                    setFIU(true)
                }
            }

        } catch (error) {
            toast.error('Une erreur est survenue')
        }
    }

    function dispatchBtnActivation() {
        if (authInfos.activated) {
            return true;
        } else {
            return formValues.selfie_pic != "" && formValues.piece_pic != ""
        }
    }
    return (
        <div className='profil'>
            <section>

                <h1>{profilLang.formTitle}</h1>
                <form action="" onSubmit={(e) => handleSubmit(e)}>
                    {
                        authInfos.activated ? <>
                            <Input
                                props={{
                                    type: 'text', name: 'name',
                                    ph: fieldsLang.firstname.ph, label: fieldsLang.firstname.label, withIcon: true,
                                    formValues, setFormValues,
                                }}
                            />
                            <Input
                                props={{
                                    type: 'text', name: 'firstname', ph: fieldsLang.lastname.ph,
                                    label: fieldsLang.lastname.label,
                                    withIcon: true, formValues, setFormValues,
                                }}
                            />
                            <Input
                                props={{
                                    type: 'text', name: 'phone', ph: fieldsLang.phone.ph,
                                    label: fieldsLang.phone.label,
                                    withIcon: true, formValues, setFormValues,
                                }}
                            />
                            <InputPassword
                                props={{
                                    ph: fieldsLang.password.ph, name: "password",
                                    label: fieldsLang.password.label,
                                    formValues, setFormValues,
                                }}
                            />
                            <InputPasswordConfirmation
                                props={{
                                    ph: fieldsLang.confirmPassword.ph, name: "confirm_password",
                                    label: fieldsLang.confirmPassword.label,
                                    pwdFieldValue: formValues.password,
                                    formValues, setFormValues,
                                }}
                            />
                            <FormBtn props={{
                                content: "Enregistrer les modifications", loader: false,
                                btnIsActive: dispatchBtnActivation()
                            }} />
                        </> : <>
                            {
                                fileIsUploaded ?
                                    <OnStageMessage /> :
                                    <>
                                        <div className="formSegmentFile">
                                            <label htmlFor="">{fieldsLang.selfiePic.label}</label>
                                            <section>
                                                <input type="file" name='selfie_pic' onChange={handleFileChange}
                                                    accept="image/png, image/jpeg,application/pdf"
                                                />
                                            </section>
                                        </div>
                                        <div className="formSegmentFile">
                                            <label htmlFor="">{fieldsLang.piecePic.label}</label>
                                            <section>
                                                <input type="file" name='piece_pic' onChange={handleFileChange}
                                                    accept="image/png, image/jpeg,application/pdf"
                                                />
                                            </section>
                                        </div>
                                        <FormBtn props={{
                                            content: profilLang.formBtnContent, loader: false,
                                            btnIsActive: dispatchBtnActivation()
                                        }} />
                                    </>
                            }
                        </>
                    }

                </form>
            </section>
        </div>
    )
}

export default Profil
