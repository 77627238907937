export function onlyNumber(value) {
    const reg = /^[0-9]*$/
    if (reg.test(value)) {
        return true
    }
    return false;
}
export function onlyNumberWithSpace(value) {
    const reg = /^[0-9-\s]*$/
    if (reg.test(value)) {
        return true
    }
    return false;
}

export function onlyLetter(value) {
    const reg = /^[a-zA-ZÀ-ú]*$/;
    if (reg.test(value))
        return true
    return false
}

export function onlyLetterWithSpace(value) {
    const reg = /^[a-zA-ZÀ-ú\s]*$/;
    if (reg.test(value))
        return true
    return false
}

export function isLetterOrNumber(value) {
    const reg = /^[a-zA-ZÀ-ú-0-9]*$/;
    if (reg.test(value))
        return true
    return false
}

export function isLetterAndNumber(value) {
    const reg = new RegExp("^(?=.*[0-9])");
    if (reg.test(value))
        return true
    return false
}

export function onlyLetterAndNumberWithSpace(value) {
    const reg = /^[a-zA-ZÀ-ú-0-9\s]*$/;
    if (reg.test(value))
        return true
    return false
}

export function isMail(value) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return (true)
    }
    return false;
}


export function hasMinSize(value, min) {
    if (value.length >= min) {
        return (true)
    }
    return false;
}

export function hasMaxSize(value, max) {
    if (value.length <= max) {
        return (true)
    }
    return false;
}