import React, { useState } from 'react'
import Button, { ButtonLink } from '../button/button'
// import { Link } from 'react-router-dom'
import "./nav.css"
import { CircleFlagsEn, CircleFlagsFr } from '../icons/flags';
import { GgMenuMotion, MdiLogout } from '../icons/nav';
import Dropdown from './dropdown';
import { SimpleImage } from '../image/image';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebar } from '../../store/actions/sidebarAction';
import sidebarSelector from '../../store/selectors/sidebarSelector';
import { toggleLang } from '../../store/actions/langActions';
import langSelector from '../../store/selectors/langSelector';
import { HashLink as Link } from 'react-router-hash-link';

export function SimpleNav({ props }) {
    const { navLinksArray, logoPath, auth } = props;
    const [mobileNav, setMobileNav] = useState(false);
    const dispatch = useDispatch();


    return (
        <div className='simpleNav'>
            <section className='sn-logo'>
                <Link to="/">
                    <SimpleImage props={{ src: logoPath, alt: "SeveXChange Logo" }} />
                </Link>
            </section>
            {/* <LangSection props={{ dispatch }} /> */}
            <SimpleNavLinksAndAction navLinksArray={navLinksArray} auth={auth} />

            <section className='sn-menuHambuger'>
                <GgMenuMotion onClick={() => setMobileNav(!mobileNav)} />
                <div className={mobileNav ? "snmh-showMobileNav" : ""}>
                    <SimpleNavLinksAndAction navLinksArray={navLinksArray} />
                </div>
            </section>
        </div>
    )
}

export function SimpleNavLinksAndAction({ navLinksArray, auth }) {
    const lang = useSelector(langSelector).langs
    return <>
        <section className='sn-links'>
            {
                lang.simpleNav.links.map((nla, index) => <Link smooth key={"nav link nb" + index}
                    to={nla.link}>
                    {
                        nla.label
                    }
                </Link>)
            }
        </section>
        {
            auth ? <section className='sn-actions'>
                <Link to="/client-dashboard">
                    <Button props={{ content: "Mon dahsboard", color: 1, rounded: true }} />
                </Link>
            </section> : <>
                <section className='sn-actions'>
                    <ButtonLink props={{ content: "Se connecter ", link: "/auth/login" }} />
                    <Link to="/auth/signup">
                        <Button props={{ content: "S'inscrire", color: 1, rounded: true }} />
                    </Link>
                </section>
            </>
        }

    </>
}


export function DashboardNav({ props }) {
    const { logoPath, user, logout } = props;
    const dispatch = useDispatch();
    const currentLangs = useSelector(langSelector).current
    const sideBar = useSelector(sidebarSelector);
    return (
        <div className='adminNav'>
            <section className='sn-menuHambuger'>
                <GgMenuMotion onClick={() => dispatch(setSidebar(!sideBar))} />
            </section>

            {/* 
            <section className='an-search'>
                <InputSearch props={{ type: "search", ph: "Chercher ici", label: "", withIcon: true, iconPosition: "left" }} />
            </section> */}

            {/* <LangSection props={{ dispatch }} /> */}

            {/* <section className='an-notice'>
                <article className='an-toggler'>
                    <MdiBell />
                    <span>1</span>
                </article>
            </section> */}

            <section className='an-user'>

                <Dropdown props={{
                    toggler: <div className='anu-infos'>
                        <SimpleImage props={{ src: "userDefault.svg" }} />
                        <p>{user?.email}</p>
                    </div>,
                    childsArrays: [
                        { label: <Link to="/profil/update">Mon profil</Link> },
                        {
                            label: <span className='disconectBtn' onClick={logout}>
                                <MdiLogout />
                                Déconnexion</span>
                        }
                    ]
                }} />
            </section>
        </div>
    )
}


export function LangSection({ props }) {
    const { dispatch } = props;
    const currentLangs = useSelector(langSelector).current
    return <section className='an-langs'>
        <Dropdown props={{
            toggler: <>
                {
                    currentLangs === "fr" ? <CircleFlagsFr className="navFlagSize" onClick={() => dispatch(toggleLang('fr'))} />
                        : <CircleFlagsEn className="navFlagSize" onClick={() => dispatch(toggleLang('en'))} />
                }
            </>, childsArrays: [
                { label: <CircleFlagsEn className="navFlagSize" onClick={() => dispatch(toggleLang('en'))} /> },
                { label: <CircleFlagsFr className="navFlagSize" onClick={() => dispatch(toggleLang('fr'))} /> }
            ]
        }} />
    </section>
}