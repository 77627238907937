import React from 'react'
import Chart2 from '../../UiKIts/chart/chart2'
import Chart1 from '../../UiKIts/chart/chart1'
import { Tabs2 } from '../../UiKIts/tabs/tabs'
import { fakeStats } from '../../rawDatas/fakeStats'
import Chart3 from '../../UiKIts/chart/chart3'
import { BiCurrencyExchange } from '../../UiKIts/icons/nav'
import { getTodayStats, getMonthStats, getWeekStats, getThreeLastMonthStats } from '../../utils/stats'

export function Dash_Vertical_Tabs({ transactions }) {
    const tabsArray = [
        {
            name: "Jour", result: <Dvt_Result props={{
                setStats: fakeStats.jours.send,
                receiveStats: fakeStats.jours.receive,
                totalReceive: getTodayStats(transactions).receives,
                totalSet: getTodayStats(transactions).sets,
            }} />
        },
        {
            name: "Semaine", result: <Dvt_Result props={{
                setStats: fakeStats.semaine.send,
                receiveStats: fakeStats.semaine.receive,
                totalReceive: getWeekStats(transactions).receives,
                totalSet: getWeekStats(transactions).sets,
            }} />
        },
        {
            name: "Mois", result: <Dvt_Result props={{
                setStats: fakeStats.mois.send,
                receiveStats: fakeStats.mois.receive,
                totalReceive: getMonthStats(transactions).receives,
                totalSet: getMonthStats(transactions).sets,
            }} />
        },

    ]
    return (
        <div className='dhh-right'>
            <section>
                <Tabs2 props={{ tabsArray, labelCenter: true }} />
            </section>

        </div>
    )
}

export function Dvt_Result({ props }) {
    const { setStats, receiveStats, totalReceive, totalSet } = props
    return <section className='dvt-results'>
        <article className='dvtr1'>
            <div className='dvtr1-charts'>
                <section>
                    <article>
                        <Chart1 props={{ color: "#51459E", data: receiveStats }} />
                    </article>
                    <p>
                        <b>Total recu</b>
                        <br />
                        <span>{totalReceive ? totalReceive : 0} Fcfa</span>
                    </p>
                </section>
                <section>
                    <article>
                        <Chart1 props={{ color: "#FF92A4", data: setStats }} />
                    </article>
                    <p>
                        <b>Total envoyé</b>
                        <br />
                        <span>{totalSet ? totalSet : 0} Fcfa</span>
                    </p>
                </section>
            </div>
        </article>
        <article className='dvtr2'>
            <Chart2 />
        </article>
    </section>
}

export function Dash_Horizontal_Stats({ transactions }) {

    return (
        <div className='dhhl-chart'>
            <article className='dhhlc-titles'>
                <p>
                    <BiCurrencyExchange />
                    Evolution des ventes et achats
                </p>

                <div>
                    <p>
                        <span className='chartHorizontalColor1'></span>
                        Envoyé
                    </p>
                    <p>
                        <span className='chartHorizontalColor2'></span>
                        Recu
                    </p>
                </div>
            </article>
            <section>
                <Chart3 data={getThreeLastMonthStats(transactions)} />
            </section>
        </div>
    )
}

