import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

function useClients() {
    const [clients, setClients] = useState([]);
    const [reload, setReload] = useState(false);


    async function getAgregator() {
        try {
            const req = await axios.get('/user/all')
            setClients(req.data.data)
        } catch (err) {
            toast.error('Erreur avec les clients')
        }
    }

    useEffect(() => {
        getAgregator();
        reload && setReload(false);
    }, [reload])


    function reloadClients() {
        setReload(true)
    }


    return {
        clients, reloadClients
    }
}

export default useClients;