const enAuthLang = {
    auth: {
        signIn: {
            left: {
                title: 'Trade safely'
            },
            right: {
                googleBtn: "Connect with Google",
                title: "Log in",
                btn: "Connect with google",
                mail: {
                    label: "Email",
                    placeHolder: "Your email"
                },
                password: {
                    label: "Password",
                    placeHolder: "Your password"
                },
                formBtn: "Connect",
                forgetPasswordText: "Forget Password",
                otherAuthText: "I don't have an account",
                otherAuthBtn: "Create Account"
            }
        },
        signUp: {
            left: {
                title1: 'Create your account now and trade securely'
            },
            right: {
                googleBtn: "Register with Google",
                title: "Register",
                name: {
                    label: 'Name', placeHolder: 'Your name'
                },
                firstname: {
                    label: 'First names', placeHolder: 'Your first names'
                },
                mail: {
                    label: "Email", placeHolder: "Your email"
                },
                passWord: {
                    label: "Password",
                    placeHolder: "Your password"
                },
                passWord2: {
                    label: "Confirm Password",
                    placeHolder: "Confirm password"
                },
                formBtn: "Register",
                otherAuthText: "I already have an account",
                otherAuthBtn: "Log in"

            }
        },
    }
}
export default enAuthLang;

