import React from 'react'
import "./modal.css"
import { MdiClose } from '../icons/close'
import { MdiAlert } from '../icons/alerts'
import { useSelector } from 'react-redux'
import modalSelector from '../../store/selectors/modalSelector'
import { useDispatch } from 'react-redux/es/hooks/useDispatch'
import { setModal, setModalContent } from '../../store/actions/modalActions'
import { useLocation } from 'react-router'
import { useEffect } from 'react'

function Modal() {
    const modalState = useSelector(modalSelector);
    const dispatch = useDispatch();
    const location = useLocation();

    function closeModal() {
        dispatch(setModalContent(null))
        dispatch(setModal(false))
    }

    useEffect(() => closeModal(), [location.pathname])
    if (!modalState.show) {
        return;
    }

    return (
        <div className='modal'>
            <div className='modalBody'>
                <section className='mb-closer'>
                    <MdiClose onClick={() => closeModal()} />
                </section>
                <section className='mb-content'>
                    {/* <ConfirmationModal /> */}
                    {modalState.content}
                </section>
            </div>
        </div>
    )
}

export function ConfirmationModal({ props }) {
    const { title, text, whenConfirm } = props;
    return <div className='confirmationModal'>
        <MdiAlert />
        <h1>{title}</h1>
        <br />
        <p>
            {text}
        </p>
        <br />
        <section>
            {/* <Button props={{ content: "Oui", color: 2, noBackground: true }} />
            <Button props={{ content: "Non", color: 2 }} /> */}
            <button>Annuler</button>
            <button onClick={whenConfirm}>Confirmer</button>
        </section>
    </div>
}
export default Modal
