import axios from "axios";
export function setAuthToken(token) {
    return {
        type: "SET_AUTH_TOKEN",
        payload: token
    }
}

export function setAuth(status) {
    return {
        type: "SET_AUTH_STATUS",
        payload: status
    }
}


export function getTheUser() {

    let token = localStorage.getItem('auth-token')
    if (!token) {
        return {
            type: "SET_USER_INFOS",
            payload: null
        }
    }
    return async (dispatch) => {
        try {
            const userReq = await axios.get('/user/userInfo', {
                headers: {
                    "x-auth-token": token
                }
            });

            if (userReq.data.data) {
                return dispatch({
                    type: "SET_USER_INFOS",
                    payload: userReq.data.data
                })
            }
        } catch (error) {
            return dispatch({
                type: "SET_USER_INFOS",
                payload: null
            })
        }
    }
}

export function setTheUser(user) {
    return {
        type: "SET_USER_INFOS",
        payload: user
    }
}

export function logout() {
    return {
        type: "SET_LOGOUT",
        payload: { token: null, user: null, authed: false }
    }
}