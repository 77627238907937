import { useState } from "react";
import "./tabs.css"
import { useEffect } from "react";
export function Tabs1({ props }) {
    const [currentTabs, setCurrenttabs] = useState(0)
    const { tabsArray } = props;
    const [tabsView, setTabsView] = useState(false)


    useEffect(() => {
        setTabsView(tabsArray);
    }, [tabsArray])
    return <div className="tabs">
        <section className="tabsLabels">
            {
                tabsView && tabsView.map((tal, index) => <p key={"tal nb+" + index}
                    className={index === currentTabs ? "tabsSelected" : ""}
                    onClick={() => index != currentTabs && setCurrenttabs(index)}>
                    {
                        tal.name
                    }
                </p>)
            }
        </section>
        <section className="tabsResult">
            <div>
                {
                    tabsArray[currentTabs].result
                }
            </div>
        </section>
    </div>
}

export function Tabs2({ props }) {
    const [currentTabs, setCurrenttabs] = useState(0)
    const { tabsArray, labelCenter } = props;
    return <div className="tabs">
        <section className={"tabsLabels " + (labelCenter && "tl-center")} >
            {
                tabsArray.map((tal, index) => <p key={"tal nb+" + index}
                    className={index === currentTabs ? "tabsSelected2" : ""}
                    onClick={() => index != currentTabs && setCurrenttabs(index)}>
                    {
                        tal.name
                    }
                </p>)
            }
        </section>
        <section className="tabsResult">
            <div>
                {
                    tabsArray[currentTabs].result
                }
            </div>
        </section>
    </div>
}