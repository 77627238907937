import React from 'react'

function Term_And_Condition() {
    return (
        <div className='termsAndCondition'>
            <h1>Termes et conditions</h1>

            Le Site internet

            Le Site internet (https://sevexchange.com/) appartient à Sevexchange, une société par actions simplifiée (SAS) enregistrée au RCCM de la République du Bénin sous le numéro RB/ABC/21 B 4180 et dont le siège social est Cotonou - Bénin .

            Le Directeur de la publication est OKOUIN Babatunde Marius

            3. Contact commercial support@sevexchange.com


            4. Hébergement

            Le site et les services web https://sevexchange.com/ sont hébergés par la société :

            Planet Hoster, Inc.
        </div>
    )
}

export default Term_And_Condition
