import React from 'react'
import { Link } from 'react-router-dom';
import "./button.css"

function Button({ props }) {
    const { content, color, rounded, noBackground, functionAfterClick, size } = props;

    const btnSizeStyle = size && size == "mini" ? { padding: "2px 5px" } : {}

    return (
        <button className={(noBackground ?
            "btnNoBackground btnNoBackground" + color : "btnBg btnBg" + color) + " "
            + (rounded ? "btnRounded" : "btnSimple")}
            style={btnSizeStyle}
            onClick={() => {
                functionAfterClick && functionAfterClick(true);
            }}>
            {content}
        </ button>
    )
}

export function ButtonLink({ props }) {
    const { content, link } = props;
    return <Link to={link} className={"btnLinks"}>
        {content}
    </Link>
}

export default Button
