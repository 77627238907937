import axios from "axios"
import { toast } from "react-toastify"
import { ConfirmationModal } from "../../../UiKIts/modal/modal"
import { setModal, setModalContent } from "../../../store/actions/modalActions"


export function ActiveClientAccount({ data, reloadClients, dispatch }) {

    async function activation() {
        try {
            axios.put('/user/activate/' + data._id)
            dispatch(setModal(false))
            dispatch(setModalContent(null))
            toast.success('Activation effectuée')
            reloadClients();
        } catch (error) {
            toast.error('Une erreur est survenue')
        }
    }
    return <>
        <ConfirmationModal props={{
            title: "Voulez vous vraiment activer le compte de ce client",
            text: " Après activation le client pourra faire des échanges",
            whenConfirm: activation
        }} />
    </>
}
export function DisactiveClientAccount({ data, reloadClients, dispatch }) {

    async function disactivation() {
        try {
            axios.put('/user/disactivate/' + data._id)
            dispatch(setModal(false))
            dispatch(setModalContent(null))
            toast.success('Desactivation effectuée')
            reloadClients();
        } catch (error) {
            toast.error('Une erreur est survenue')
        }
    }
    return <>
        <ConfirmationModal props={{
            title: "Voulez vous vraiment desactiver le compte de ce client",
            text: " Après desactivation le client ne pourra plus faire des échanges",
            whenConfirm: disactivation
        }} />
    </>
}