import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

function useAgregator() {
    const [agregators, setAgregators] = useState([]);
    const [reload, setReload] = useState(false);


    async function getAgregator() {
        try {
            const req = await axios.get('/agregator')
            setAgregators(req.data.data)
        } catch (err) {
            console.log('crypto marche pas')
        }
    }

    useEffect(() => {
        getAgregator();
        reload && setReload(false);
    }, [reload])


    function reloadAgregators() {
        setReload(true)
    }


    return {
        agregators, reloadAgregators
    }
}

export default useAgregator;