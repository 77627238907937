const frDashboardTrad = {
    dashboard: {
        clientHeadBanner: {
            text: `Content de vous revoir ! Ici, vous pouvez consulter vos dernières transactions ou en faire.`,
            btnText: ""
        },
        clientAdminCountStats: {

        },
        logoutBtnText: "Se déconnecter",
        exchange: {
            historic: {
                title: 'Transaction récentes',
                viewAllTransactionBtn: 'Voir toutes les transaction',
                fitterText1: 'Tout',
                input1Placeholder: 'Chercher un utilisateur',
                input2Placeholder: 'Tout voir',
                arrayTitles: {
                    id: 'Numero de transaction',
                    type: 'Type de transaction',
                    amount: 'Montant',
                    state: 'statut',
                },
                typeTransaction: [
                    "Crypto à Momo", "Momo à Crypto", "Carte à Momo", "Carte à Crypto"
                ],
                statusList: [
                    "pending", "aborted", "validated"
                ]
            },
        },
        profil: {
            formTitle: "Modifier votre profil",
            formBtnContent: "Enregistrer les modifications",
            formFields: {
                firstname: {
                    ph: "Nom", label: "Nom"
                },
                lastname: {
                    ph: "Prénom", label: "Prénom"
                },
                phone: {
                    ph: "Télephone", label: "Télephone"
                },
                password: {
                    ph: "Mot de passe", label: "Mot de passe"
                },
                confirmPassword: {
                    ph: "Confirmer mot de passe", label: "Confirmer mot de passe"
                },

            }
        }
    },
}

export default frDashboardTrad;