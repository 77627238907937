import React from 'react';
import Button from '../../UiKIts/button/button';
import userDashboard from '../../Assets/images/banners/userDashboard.jpg';
import { HashLink as Link } from 'react-router-hash-link';

function HeadBanner({ lang }) {
  return (
    <section className='head-banner'>
      <div className='hb-left'>
        <h1>
          {lang.title1}
        </h1>
        <p>
          {lang.title2}
        </p>
        <div>
          <Link smooth to={'/#howItWorks'}>
            <Button
              props={{
                content: 'Explorer',
                color: 1,
                rounded: true,
                noBackground: true,
              }}
            />
          </Link>{' '}
          <Link to="/exchange/transactions">
            <Button
              props={{
                content: 'Proceder a un échange', color: 1, rounded: true,
              }}
            />
          </Link>
        </div>
      </div>
      <div className='hb-right'>
        <div className='laptop'>
          <div className='content'>
            <img
              className='img-responsive img-obj'
              src={userDashboard}
              alt='Banner'
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeadBanner;
