import { matchMobile } from "../../utils/responsive";
const defaultValue = matchMobile() ? false : true;


export default (state = defaultValue, action) => {
    switch (action.type) {
        case 'SET_SIDEBAR':
            return action.payload
        default:
            return state;
    }
}