import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../UiKIts/button/button'
import { SimpleImage } from '../UiKIts/image/image'

function Page_404() {
    return (
        <div className='page404'>
            <SimpleImage props={{ src: "404.svg", alt: "404 image description" }} />
            <section>
                <h1>Desolé,page introuvable</h1>
                <Link to="/">
                    <Button props={{ color: 1, content: "Revenir a l'accueil" }} />
                </Link>
            </section>
        </div>
    )
}

export default Page_404
