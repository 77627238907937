const frAuthLang = {
    auth: {
        signIn: {
            left: {
                title: 'Echanger en toute sécurité'
            },
            right: {
                googleBtn: "Se connecter avec Google",
                title: "Connectez-vous",
                btn: "Se Connecter avec google",
                mail: {
                    label: "Email",
                    placeHolder: "Votre email"
                },
                password: {
                    label: "Mot de passe",
                    placeHolder: "Votre mot de passe"
                },
                formBtn: "Se connectez",
                forgetPasswordText: "Mot de passe oublier",
                otherAuthText: "Je n'ai pas de compte",
                otherAuthBtn: "Créer un Compte"
            }
        },
        signUp: {
            left: {
                title1: 'Créez votre compte dès maintenant et Echanger en toute sécurité'
            },
            right: {
                googleBtn: "S'inscrire avec Google",
                title: "Inscrivez-vous",
                name: {
                    label: 'Nom', placeHolder: 'Votre nom'
                },
                firstname: {
                    label: 'Prénoms', placeHolder: 'Vos prénoms'
                },
                mail: {
                    label: "Email", placeHolder: "Votre email"
                },
                phone: {
                    label: "Téléphone", placeHolder: "Votre téléphone"
                },
                passWord: {
                    label: "Mot de passe",
                    placeHolder: "Votre mot de passe"
                },
                passWord2: {
                    label: "Confirmer mot de passe",
                    placeHolder: "Confirmer mot de passe"
                },
                formBtn: "S'inscrire",
                otherAuthText: "J' ai déjà un compte",
                otherAuthBtn: "Se connecter"

            }
        },
    }
}
export default frAuthLang;