import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getTheUser, setAuth, setAuthToken, setTheUser } from '../../store/actions/authActions';
import { TablerLoader2 } from '../../UiKIts/icons/loader';
import { SimpleImage } from '../../UiKIts/image/image';

function AuthWithGoogle() {
    const [loader, setLoader] = useState(true);
    const [authError, setAuthError] = useState(false);
    const dispatch = useDispatch();
    const requestUrl = process.env.NODE_ENV === "production" ? "https://sevexchange.com" : "http://localhost:3006"
    useEffect(() => {
        axios.get(requestUrl + "/google/auth/session")
            .then(res => {
                if (res.data.user && res.data.token) {
                    setTimeout(() => {
                        axios.defaults.headers = {
                            "x-auth-token": res.data.token
                        }
                        dispatch(setAuth(true))
                        dispatch(setAuthToken(res.data.token))
                        dispatch(setTheUser(res.data.user))
                        setLoader(false);
                    }, 5000)
                    // console.log(res.data)
                }
            })
            .catch(res => {
                setAuthError('Une erreure est survenue avec  votre compte google')
                setLoader(false);
            })
    }, []);



    return (
        <div className='googleAuthPart' >
            <article>
                {
                    loader ? <>
                        <h3>Authentification...</h3>
                        <SimpleImage props={{ alt: "Google auth session loader", src: "section_loader.svg" }} />
                    </> : ""
                }
            </article>
            {
                authError && <b>
                    {authError}
                </b>
            }
        </div>
    )
}

export default AuthWithGoogle
