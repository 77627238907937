import React from 'react'
import { useParams } from 'react-router'
import Account_ChangePassword from '../RouteComponents/account/changePassword';
import AccountVerify from '../RouteComponents/account/verify';

function Account() {
    const params = useParams();
    const { action, actionParam } = params;

    const accountComponents = {
        verify: <AccountVerify props={{ actionParam }} />,
        changePassword: <Account_ChangePassword props={{
            mailHasSet: actionParam ? true : false,
            mailToken: actionParam ? actionParam : false
        }} />
    }

    if (!['verify', 'changePassword'].includes(action)) {
        return "PAs d'action pour le account"
    }
    return (
        <div className='account'>
            {accountComponents[action]}
        </div>
    )
}

export default Account;