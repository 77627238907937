import React from 'react';
import HeadBanner from '../RouteComponents/home/headBanner';
import '../Assets/styles/home.css';
import HowItWork from '../RouteComponents/home/howItWork';
import TauxEchanges from '../RouteComponents/home/tauxEchanges';
import HomeDevises from '../RouteComponents/home/devises';
import HomeTransactions from '../RouteComponents/home/transactions';
import HomeGallery from '../RouteComponents/home/gallery';
import HomeFaq from '../RouteComponents/home/faq';
import { useSelector } from "react-redux"
import langSelector from '../store/selectors/langSelector';

function Home() {
  const lang = useSelector(langSelector).langs;

  return (
    <>
      <div className='home bg-normal'>
        <HeadBanner lang={lang.home.banner} />
        <TauxEchanges lang={lang.home.transactions} />
        <HowItWork lang={lang.home} />
        <HomeDevises lang={lang.home} />
        <HomeTransactions lang={lang.home.advantage} />
        <HomeGallery lang={lang.home.gallery} />
        <HomeFaq lang={lang.home.faq} />
      </div>
    </>
  );
}

export default Home;
