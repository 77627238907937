import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import sidebarSelector from '../../store/selectors/sidebarSelector'
import { SimpleImage } from '../image/image'
import { useDispatch, useSelector } from 'react-redux/es/exports'
import "./sidebar.css"
import { logout } from '../../store/actions/authActions'
import { setSidebar } from '../../store/actions/sidebarAction'
import { MdiLogout } from '../icons/nav'

function Sidebar({ props }) {
    const { sideLinks } = props;
    const sideBarOpen = useSelector(sidebarSelector);
    const location = useLocation();
    const dispatch = useDispatch();

    function checkResponsive(x) {
        if (x.matches)
            return true;
        else
            return false;
    }

    useEffect(() => {
        if (checkResponsive(window.matchMedia("(max-width: 750px)"))) {
            dispatch(setSidebar(false))
        }
    }, [location.pathname])


    return (
        <aside className={'adminSidebar ' + (sideBarOpen && "adminSidebarVisible")}>
            <section className="as-logo">
                <Link to="/">
                    <SimpleImage props={{ src: "logo_horizontal.png", alt: "sidebar img  " }} />
                </Link>
            </section>

            <section className="as-links">
                {
                    sideLinks.map((sl, index) => <Link to={sl.link} key={"sidebar lin nb" + index}
                        className={location.pathname === sl.link ? "asl-active" : ""}
                    >

                        {sl.icon}
                        {sl.label}
                    </Link>)
                }
            </section>
            <section className='as-logout'>
                <span className={"disconectBtn"} onClick={() => dispatch(logout())}>
                    <MdiLogout />
                    Se deconnecter</span>
            </section>
        </aside>
    )
}

export default Sidebar
