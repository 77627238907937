const frHomeLang = {
    home: {
        banner: {
            title1: <>
                Transférer de <b>l’argent <br />
                    virtuel</b> partout dans le Monde
            </>,
            title2: `Faites plaisir à vos proches, Vendez, achetez, échangez vos monnaies en ligne,
            avec un moyen éfficace, pour les particuliers et les entreprises.`
        },
        transactions: {
            makedText: "Transaction éffectué",
            list: [
                {
                    img: 'mtn.png', title: 'Momo à crypto',
                    count: 680
                },
                {
                    img: 'card.png', title: 'Carte bancaire à momo',
                    count: 780
                },
                {
                    img: 'card.png', title: 'Carte bancaire à crypto',
                    count: 1205
                },
            ]
        },
        advantage: {
            title1: `Nous vous offrons`,
            title2: `LES MEILLEURS TAUX D'ECHANGE`,
            title3: `obtenez la valeur de votre échange crypto et passez à l'échange rapide et sécurisé`
        },
        gallery: {
            galleryElements: [
                {
                    background: 'btc.png',
                    title1: 'Echange rapide de Momo à Cryto',
                    title2: 'Opération rapide et sécurisé,soigné livré en 24h',
                    link: '#momo'

                }, {
                    background: 'card.png',
                    title1: 'Echange rapide de Carte bancaire à Momo',
                    title2: 'Opération rapide et sécurisé,soigné livré en 24h',
                    link: '#carteM'

                }, {
                    background: 'card.png',
                    title1: 'Echange rapide de Momo a Carte bancaire',
                    title2: 'Opération rapide et sécurisé,soigné livré en 24h',
                    link: '#carteC'
                }
            ]
        },
        faq: {
            questionList: [
                {
                    question: "Est-ce que SevExchange est une plateforme de vente de cryptomonnaie ?",
                    answer: `Non ! SevExchange n’est pas une plateforme de vente de cryptomonnaie. 
                    Toutefois, elle vous permet de pouvoir échanger quelques types de monnaies électroniques contre d’autres devises comme le CFA et vice versa. 
                    Vous pouvez aussi utiliser la plateforme pour envoyer des cryptomonnaies à d’autres personnes à partir de vos cartes bancaires. `
                }, {
                    question: "Comment fais-je pour bénéficier de la plateforme ?",
                    answer: `Pour bénéficier de la plateforme, vous avez juste à créer votre compte et le tout est joué.
                     Pour ce faire, vous vous inscrivez avec votre nom, prénoms et un mot de passe.
                     C’est un peu comme la plupart des sites que vous avez déjà l’habitude d’utiliser. Très simple comme bonjour ! `
                }, {
                    question: "Quel est le taux d’intérêt que la plateforme prélève sur mes transactions ?",
                    answer: "Le taux d'interet appliqué est de 10%."
                }, {
                    question: "Est-il possible de faire les transactions avec un compte PayPal ?",
                    answer: `Pour l’instant, c’est non ! Les transactions directes avec PayPal ne sont pas encore disponibles,
                     mais avec le temps, cette fonctionnalité sera ajoutée. Néanmoins, vous pouvez utiliser vos cartes bancaires comme alternative. Donc, vous transférez d’abord les fonds de votre compte PayPal vers votre carte bancaire.
                     Ensuite, vous transférez de la carte vers la plateforme. `
                }, {
                    question: "J'ai des Bitcoins et je voudrais les échanger contre des Dogecoins. Est-ce possible ?",
                    answer: `Non ! Nous ne faisons pas les transactions d’une cryptomonnaie vers un autre type de cryptomonnaie. Les échanges possibles sur notre site pour le moment sont uniquement de : 
                    Cryptomonnaie à mobile money ; 
                    Mobile money à Cryptomonnaie ; 
                    Carte bancaire à Cryptomonnaie ;
                    Carte bancaire à mobile money. `
                }
            ]
        }
    }
}

export default frHomeLang;