import axios from 'axios';

// const headers = async () => {
// 	let session = await getSession();
// 	return session ? {
// 		headers: {
// 			"x-auth-token": session.user.token
// 		}
// 	} : {}
// }

const apiURL = process.env.REACT_APP_API_URL;

export const login = async (data = {}, path = 'auth') => {
  try {
    let res = await axios.post(apiURL + path, data);

    return res.data;
  } catch (err) {
    return {
      error: true,
      message: err.message,
      data: null,
    };
  }
};

export const getRequest = async (path = '', headers = {}) => {
  try {
    let res = await axios.get(apiURL + path, headers);

    return res.data;
  } catch (err) {
    return {
      error: true,
      message: err.message,
      data: null,
    };
  }
};

export const postRequest = async (path = '', data = {}, headers = {}) => {
  try {
    let res = await axios.post(path, data, headers);

    return res.data;
  } catch (err) {
    return {
      error: true,
      message: err.message,
      data: null,
    };
  }
};

export const putRequest = async (path = '', data = {}, headers = {}) => {
  try {
    let res = await axios.put(apiURL + path, data, headers);

    return res.data;
  } catch (err) {
    return {
      error: true,
      message: err.message,
      data: null,
    };
  }
};

export const patchRequest = async (path = '', data = {}, headers = {}) => {
  try {
    let res = await axios.patch(apiURL + path, data, headers);

    return res.data;
  } catch (err) {
    return {
      error: true,
      message: err.message,
      data: null,
    };
  }
};

export const deleteRequest = async (path = '', headers = {}) => {
  try {
    let res = await axios.delete(apiURL + path, headers);

    return res.data;
  } catch (err) {
    return {
      error: true,
      message: err.message,
      data: null,
    };
  }
};
