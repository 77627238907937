const enHomeLang = {
    home: {
        banner: {
            title1: <>
                Transfer <b>money<br />
                    virtual</b> anywhere in the world
            </>,
            title2: `Make your loved ones happy, Sell, buy, exchange your currencies online,
            with an effective means, for individuals and businesses.`
        },
        transactions: {
            makedText: "Transaction made",
            list: [
                {
                    img: 'mtn.png', title: 'Momo to crypto',
                    count: 680
                },
                {
                    img: 'card.png', title: 'Momo credit card',
                    count: 780
                },
                {
                    img: 'card.png', title: 'Crypto credit card',
                    count: 1205
                },
            ]
        },
        advantage: {
            title1: `We offer you`,
            title2: `THE BEST EXCHANGE RATES`,
            title3: `get the value of your crypto exchange and switch to fast and secure exchange`
        },
        gallery: {
            galleryElements: [
                {
                    background: 'btc.png',
                    title1: 'Momo to Cryto Quick Swap',
                    title2: 'Fast and secure operation, neat delivered in 24h',
                    link: '#momo'
                }, {
                    background: 'card.png',
                    title1: 'Quick exchange from bank card to Momo',
                    title2: 'Fast and secure operation, neat delivered in 24h',
                    link: '#carteM'
                }, {
                    background: 'card.png',
                    title1: 'Quick exchange from Momo to Bank card',
                    title2: 'Fast and secure operation, neat delivered in 24h',
                    link: '#carteC'
                }
            ]
        },
        faq: {
            questionList: [
                {
                    question: "Is SevExchange a cryptocurrency sales platform?",
                    answer: `No! SevExchange is not a cryptocurrency sales platform.
                    However, it allows you to be able to exchange some types of electronic currencies against other currencies such as the CFA and vice versa.
                    You can also use the platform to send cryptocurrencies to other people from your bank cards. `
                }, {
                    question: "How do I benefit from the platform?",
                    answer: `To benefit from the platform, you just have to create your account and everything is done.
                     To do this, you register with your name, first names and a password.
                     It's a lot like most sites you're used to using. Very simple as pie! `
                }, {
                    question: "What is the interest rate that the platform charges on my transactions?",
                    answer: "The interest rate applied is 10%."
                }, {
                    question: "Is it possible to make transactions with a PayPal account?",
                    answer: `For now, no! Direct transactions with PayPal are not yet available,
                     but over time this functionality will be added. However, you can use your bank cards as an alternative. So you first transfer the funds from your PayPal account to your credit card.
                     Then you transfer from the card to the platform. `
                }, {
                    question: "I have Bitcoins and I would like to exchange them for Dogecoins. Is this possible?",
                    answer: `No! We do not transact from one cryptocurrency to another type of cryptocurrency. The possible exchanges on our site for the moment are only:
                    Cryptocurrency to mobile money;
                    Mobile money to Cryptocurrency;
                    Cryptocurrency bank card;
                    Mobile money bank card.
                    `
                }
            ]
        }
    }
}

export default enHomeLang;