import moment from "moment";
export function calcTotal(datas, type) {
    let total = 0;
    for (let i = 0; i < datas.length; i++) {
        if (datas[i].type.endsWith(type)) {
            total = total + datas[i].amount;
        }
    }

    return total;
}

export function getTodayStats(transactions) {
    let sets = 0;
    let receives = 0;

    for (let i = 0; i < transactions.length; i++) {
        if (transactions[i].createdAt.includes(moment().format('YYYY-MM-DD'))) {
            if (transactions[i].status === "pending")
                receives = receives + transactions[i].amount
            else if (transactions[i].status === "validated")
                sets = sets + transactions[i].amount
        }
    }
    return { sets, receives }
}


export function getWeekStats(transactions) {
    let sets = 0;
    let receives = 0;

    for (let i = 0; i < transactions.length; i++) {
        if (moment().diff(transactions[i].createdAt, "days") <= 7) {
            if (transactions[i].status === "pending")
                receives = receives + transactions[i].amount
            else if (transactions[i].status === "validated")
                sets = sets + transactions[i].amount
        }
    }
    return { sets, receives }
}

export function getMonthStats(transactions) {
    let sets = 0;
    let receives = 0;

    for (let i = 0; i < transactions.length; i++) {
        if (moment().diff(transactions[i].createdAt, "days") <= 30) {
            if (transactions[i].status === "pending")
                receives = receives + transactions[i].amount
            else if (transactions[i].status === "validated")
                sets = sets + transactions[i].amount
        }
    }
    return { sets, receives }
}


export function getThreeLastMonthStats(transactions) {
    let threeMonths = [
        { month: moment().subtract(2, "month").format('MMMM'), receive: 0, set: 0 },
        { month: moment().subtract(1, "month").format('MMMM'), receive: 0, set: 0 },
        { month: moment().format('MMMM'), receive: 0, set: 0 },
    ];
    for (let i = 0; i < transactions.length; i++) {
        let transactionMonth = moment(transactions[i].createdAt).format('MMMM')
        const findTheMonth = threeMonths.findIndex(el => el.month === transactionMonth);
        if (findTheMonth > -1) {
            if (transactions[i].status === "pending")
                threeMonths[findTheMonth].receive = threeMonths[findTheMonth].receive + transactions[i].amount
            else if (transactions[i].status === "validated")
                threeMonths[findTheMonth].set = threeMonths[findTheMonth].set + transactions[i].amount
        }
    }
    return threeMonths;
}