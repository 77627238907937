import { treatAndDisplay } from './helpers';
import { postRequest } from './request';

export const getObj = (formValues) => {
  let obj = {
    userId: formValues.user._id,
    type: '',
    status: 'pending',
    amount: '',
    from: 'INFO_CLIENT_FROM',
    to: '',
    otherInfo: {
      from: {},
      to: {},
    },
    transactionId: '',
  };

  switch (formValues.typeTransaction) {
    case 'cryptoToMomo':
      obj = {
        ...obj,
        type: formValues.typeTransaction,
        amount: formValues.amount,
        from: formValues.linkWallet,
        to: formValues.numMomo,
        transactionId: formValues.idTrans,
        otherInfo: {
          devise: formValues.typeCrypto, fullName: formValues.fullName
        },
      };
      break;

    case 'momoToCrypto':
      obj = {
        ...obj,
        type: formValues.typeTransaction,
        amount: formValues.amount,
        from: formValues.my_num,
        to: formValues.linkWallet,
        transactionId: formValues.idTrans,
        otherInfo: {
          fullName: formValues.user.firstname + " " + formValues.user.name,
          devise: formValues.typeCrypto,
        },
      };
      break;

    case 'cardToMomo':
      obj = {
        ...obj,
        type: formValues.typeTransaction,
        amount: formValues.amount,
        from: formValues.user.firstname + " " + formValues.user.name,
        to: formValues.numMomo,
        transactionId: formValues.idTrans,
        otherInfo: {
          fullName: formValues.fullName
        },
      };
      break;

    case 'cardToCrypto':
      obj = {
        ...obj,
        type: formValues.typeTransaction,
        amount: formValues.amount,
        from: formValues.user.firstname + " " + formValues.user.name,
        to: formValues.linkWallet,
        transactionId: formValues.idTrans,
        otherInfo: {
          devise: formValues.typeCrypto,
        },
      };
      break;

    default:
      break;
  }
  return obj;
};

export const doTransaction = async (e, formValues, navigate) => {
  const transObj = getObj(formValues);
  e.preventDefault();
  // console.log(transObj);
  const data = treatAndDisplay(
    await postRequest('/transaction', transObj)
  );

  if (data) {
    navigate('/exchange/historic');
  }
};
