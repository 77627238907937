import React from 'react'
import "./footer.css"
import { FaBrandsTiktok, MdiFacebook, MdiWhatsapp } from '../icons/socials'
import { Link } from "react-router-dom"
import { MdiChevronRight } from '../icons/chevrons'
import { SimpleImage } from '../image/image'

function Footer1({ lang }) {
    return (
        <footer>
            <section>
                <SimpleImage props={{ src: "logo_white.png", alt: "Footer logo" }} />
                <p>{lang.part1.mail}</p>
                <p>{lang.part1.tel} </p>
            </section>


            <article className='footer-fastLinks'>
                <h3>{lang.part2.title}</h3>
                <div>
                    {
                        lang.part2.links.map((ci, index) => <Link to={ci.href} key={"footer 1 lk nb" + index}>
                            <MdiChevronRight />
                            {ci.label}
                        </Link>)
                    }
                </div>

            </article>

            <article className='footer-socials'>
                <h3>{lang.part3.title}</h3>
                <br />
                <div>
                    <a href="https://www.facebook.com/Sevexchange/" target={"_blank"}>
                        <MdiFacebook />
                    </a>
                    <a href="https://wa.link/9snb4u" target={"_blank"}>
                        <MdiWhatsapp />
                    </a>
                    <a href="https://www.tiktok.com/discover/Sevexchange?lang=en" target={"_blank"}>
                        <FaBrandsTiktok />
                    </a>
                </div>

            </article>

            <h4>
                &copy; Copyright 2022 Sevexchange. All right reserved
            </h4>
        </footer>
    )
}

export default Footer1
