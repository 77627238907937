import ClientDashboard from '../Routes/ClientDashboard';
import Home from '../Routes/Home';
import Auth from '../Routes/Auth';
import Account from '../Routes/Account';
import AdminDAshboard from '../Routes/AdminDashboard';
import Profil from '../Routes/Profil';
import Exchange from '../Routes/Exchange';
import Admin from '../Routes/AdminDashboard/Admin';
import Page_404 from '../Routes/404';
import Politique from '../Routes/Politique';
import Legal from '../Routes/Legal';
import Term_And_Condition from '../Routes/Terms';

export function routesArray(setSideBarOpen) {
  return [
    {
      path: '/', requireAuth: "both",
      comp: <Home />,
    },
    {
      path: '*', requireAuth: false,
      comp: <Page_404 />,
    },
    {
      path: '/politic', requireAuth: false,
      comp: <Politique />,
    },
    {
      path: '/terms-and-conditions', requireAuth: false,
      comp: <Term_And_Condition />,
    },
    {
      path: '/legal-mention', requireAuth: false,
      comp: <Legal />,
    },
    {
      path: '/auth/:typeAuth', requireAuth: false,
      comp: <Auth />,
    },
    {
      path: '/account/:action/:actionParam', requireAuth: false,
      comp: <Account />,
    },
    {
      path: '/account/:action', requireAuth: false,
      comp: <Account />,
    },
    {
      path: '/client-dashboard/', requireAuth: true, requireClient: true,
      comp: <ClientDashboard />,
    },
    {
      path: '/exchange/:action', requireAuth: true, requireClient: true,
      comp: <Exchange />,
    },
    {
      path: '/admin-dashboard/', requireAuth: true, requireAdmin: true,
      comp: <AdminDAshboard />,
    },
    {
      path: '/admin/:action', requireAuth: true, requireAdmin: true,
      comp: <Admin />,
    },
    {
      path: '/admin/client/:actionId/transactions', requireAuth: true, requireAdmin: true,
      comp: <Admin />,
    },
    {
      path: '/profil/:action', requireAuth: true,
      comp: <Profil />,
    }
  ];
}


export const adminRoute = [
  '/admin/agregators', '/admin/cryptos',
  '/admin/agregators', '/admin/cryptos',
  "/admin/client/"
] 