import React, { useState } from 'react'
import { MdiEyeOutline, MdiEyeOffOutline } from '../icons/password'
import { handleChange, errorToggler, sizeErrorToggler } from './formUtils/dataManager';
import { useEffect } from 'react';

export function InputPassword({ props }) {
    const { ph, label, name, fieldVerification, min, max,
        formValues, setFormValues,
        formField, setFormField } = props
    const [viewPwd, setViewPwd] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (errors.length === 0) {
            setIsError(false)
        } else {
            setIsError(true)
        }
    }, [errors])

    useEffect(() => {
        if (formValues[name] != "") {
            let sizeErrs = [];
            if (min || max) {
                sizeErrs = [...sizeErrorToggler(formValues[name], name, min ? 'hasMinSize' : 'hasMaxSize', min || max, errors, setErrors)]
            }
            if (fieldVerification) {
                if (formValues) {
                    errorToggler(formValues[name], name, fieldVerification, min || max ? sizeErrs : errors, setErrors)
                } else {
                    errorToggler(formField, name, fieldVerification, errors, setErrors)
                }
            }
        }


    }, [formValues || formField, min]);


    return (
        <div className='formFieldPwd'>
            <label htmlFor="">{label}</label>
            <section>
                <input type={!viewPwd ? "password" : "text"} className={isError ? "isError" : ""} placeholder={ph}
                    autoComplete="new-password"
                    onChange={(event) => handleChange(event,
                        formValues ? "obj" : "string",
                        formValues ? setFormValues : setFormField,
                        formValues && formValues)}
                    name={name}
                    value={formValues ? formValues[name] : formField} />
                {
                    viewPwd ? <MdiEyeOutline onClick={() => setViewPwd(!viewPwd)} /> : <MdiEyeOffOutline onClick={() => setViewPwd(!viewPwd)} />
                }
            </section>

            <article className='isErrorText'>
                {
                    isError && errors.filter((err => err.name === name || err.name == 'size')).map((er, ind) => <span key={"error txt" + name + ind}>
                        {
                            er.text + ','
                        }
                    </span>)
                }

            </article>
        </div >
    )
}


export function InputPasswordConfirmation({ props }) {
    const { ph, name, label, pwdFieldValue,
        formValues, setFormValues,
        formField, setFormField
    } = props
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(false);
    const [viewPwd, setViewPwd] = useState(false);

    useEffect(() => {
        if (!error)
            return setIsError(false)
        setIsError(true)
    }, [error]);

    useEffect(() => {
        if (formValues[name] !== '' || formField !== "") {
            if ((formValues && formValues[name] !== pwdFieldValue) || (formField && formField !== pwdFieldValue)) {
                return setError(true)
            }

            return setError(false)
        }
    }, [formValues[name] || formField])
    return (
        <div className='formFieldPwd'>
            <label htmlFor="">{label}</label>
            <section>
                <input type={!viewPwd ? "password" : "text"} placeholder={ph} name={name}
                    onChange={(event) => handleChange(event,
                        formValues ? "obj" : "string",
                        formValues ? setFormValues : setFormField,
                        formValues && formValues)}
                    value={formValues ? formValues[name] : formField}
                />
                {
                    viewPwd ? <MdiEyeOutline onClick={() => setViewPwd(!viewPwd)} /> : <MdiEyeOffOutline onClick={() => setViewPwd(!viewPwd)} />
                }
            </section>
            <article className='isErrorText'>
                {
                    isError && <span>Les mots de passe ne correspondent pas</span>
                }
            </article>
        </div>
    )
}
