export const frFooterLang = {
    footer: {
        part1: {
            img: "logo_white.png",
            mail: <><b>Mail:</b> <a className="text-light" href="mailto:support@sevexchange.com" target={"_blank"}>support@sevexchange.com</a> </>,
            tel: <><b>Phone: </b> <a className="text-light" href="tel:+229 59259741" target="_blank">229 59259741</a> </>
        },
        part2: {
            title: '',
            links: [
                {
                    href: '/',
                    label: 'Accueil',
                },
                {
                    href: '/#howItWorks',
                    label: 'Comment ça marche?'
                },
                {
                    href: '/exchange/transactions',
                    label: 'Echangeur'
                },
                // {
                //     href: '',
                //     label: 'Services'
                // },
                {
                    href: '/terms-and-conditions',
                    label: 'Termes et conditions'
                },
                {
                    href: '/politic',
                    label: 'Confidentialité'
                },
                {
                    href: '/legal-mention',
                    label: 'Mention Légale'
                }
            ]
        },
        part3: {
            title: "Réseaux sociaux",
            links: [
                { link: "", img: 'fb.svg' },
                { link: "", img: 'instagram.svg' },
                { link: "", img: 'twitter.svg' },
                { link: "", img: 'linkedIn.svg' },
            ]
        }
    }
}



export const enFooterLang = {
    footer: {
        part1: {
            img: "logo_white.png",
            mail: <><b>Mail:</b> <a href="mailto:support@sevexchange.com" target={"_blank"}>support@sevexchange.com</a> </>,
            tel: <><b>Phone: </b> <a href="tel:+229 59259741" target="_blank"></a> </>
        },
        part2: {
            title: 'Useful links',
            links: [
                {
                    href: '',
                    label: 'About',
                },
                {
                    href: '',
                    label: 'How does it work?'
                },
                {
                    href: '',
                    label: 'Exchanger'
                },
                {
                    href: '',
                    label: 'Services'
                },
                {
                    href: '',
                    label: 'Terms and conditions'
                },
                {
                    href: '',
                    label: 'Privacy'
                }
            ]
        },
        part3: {
            title: "Social Networks",
            links: [
                { link: "", img: 'fb.svg' },
                { link: "", img: 'instagram.svg' },
                { link: "", img: 'twitter.svg' },
                { link: "", img: 'linkedIn.svg' },
            ]
        }
    }
}
