import React from 'react'

export function mapComponents(datas, keyDescription, containerType, containerClass) {


    function dispatchContainer(i, data) {
        if (containerType) {
            if (containerType == "div")
                return <div key={keyDescription + "nb" + i} className={containerClass}>{data} </div>
            else if (containerType == "section")
                return <section key={keyDescription + "nb" + i} className={containerClass}>{data} </section>
            else if (containerType == "article")
                return <article key={keyDescription + "nb" + i} className={containerClass}>{data} </article>
            else if (containerType == "span")
                return <span key={keyDescription + "nb" + i} className={containerClass}>{data} </span>
            else if (containerType == "p")
                return <p key={keyDescription + "nb" + i} className={containerClass}>{data} </p>

        }
        return <div key={keyDescription + "nb" + i} className={containerClass}>{data} </div>
    }
    return (
        <>
            {
                datas.map((data, i) => dispatchContainer(i, data))
            }
        </>
    )
}
