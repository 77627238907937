import React from 'react';
import momo from '../../Assets/images/visuels/home/momo.png';
import bitcoin from '../../Assets/images/visuels/home/bitcoin.jpg';
import visa from '../../Assets/images/visuels/home/visa.jpg';
import Button from '../../UiKIts/button/button';
import { Link } from 'react-router-dom';

function HomeGallery({ lang }) {
  const imgsArray = [
    momo, visa, bitcoin
  ]
  return (
    <section className='home-gallery'>
      {
        lang.galleryElements.map((hg, id) => <GaleryItem key={"home gallery nb" + id}
          props={{
            img: imgsArray[id],
            title1: hg.title2,
            title2: hg.title1,
            link: hg.link
          }} />)
      }
    </section>
  );
}


function GaleryItem({ props }) {
  const { img, title1, title2, link } = props;

  return <div className='hg-item'>
    <img className='' src={img} alt='Image Banner' />
    <section className='hg-cover'>
      <div>
        <h3>{title1}</h3>
        <p>{title2}</p>
        <Link to={"/exchange/transactions" + link}>
          <Button props={{ content: "Cliquer ici", color: 1 }} />
        </Link>
      </div>
    </section>
  </div>
}
export default HomeGallery;
