import React from 'react';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import { useState, useCallback } from 'react';

const data = [
    {
        name: 'Page A',
        value: 4000,
    },
    {
        name: 'Page B',
        value: 3000,
    }
];

export function Chart2({ props }) {
    // const { data } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const activeItem = data[activeIndex];

    const handleClick = useCallback(
        (entry, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    return (
        <ResponsiveContainer width="100%" height={"100%"}>
            <BarChart data={data}>
                <Bar barSize={10} dataKey="value" onClick={handleClick} background={{ fill: '#eee' }} radius={20} >
                    {data.map((entry, index) => (
                        <Cell
                            cursor="pointer"
                            fill={index === activeIndex ? "#51459E" : "#FF92A4"}
                            key={`cell-${index}`}
                        />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );

}


export default Chart2;