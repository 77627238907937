import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useTransaction from '../../Hooks/useTransaction'
import { authUserSelector } from '../../store/selectors/authSelectors'
import { DashHead } from '../../RouteComponents/global/dash/layout'
import ExchangeHistoric from '../../RouteComponents/global/exchange/historic'
import langSelector from '../../store/selectors/langSelector'
import { Link } from 'react-router-dom'
import Button from '../../UiKIts/button/button'


function CD_Home() {
    const user = useSelector(authUserSelector)
    const { transactions, reloadTransactions } = useTransaction(user._id);
    const lang = useSelector(langSelector).langs
    const transactions2 = [];

    return (
        <div className='dash-home'>
            {
                !user.activated ? <NotActivatedMessage user={user} /> : <>
                    <DashHead props={{ user, transactions, lang: lang.dashboard.clientHeadBanner }} />
                    <ExchangeHistoric props={{ user, datas: transactions, reloadTransactions, lang: lang.dashboard.exchange.historic }} />
                </>
            }
        </div>
    )
}

export function NotActivatedMessage({ user }) {
    return <div className='accountNotActivated'>
        <h1>

            {
                user.selfie_pic && user.piece_pic && user.selfie_pic != null && user.piece_pic != null ?
                    "Vous avez soumis vos informations" :
                    "Votre compte n'est pas activé"
            }
        </h1>
        <p>
            {
                user.selfie_pic && user.piece_pic && user.selfie_pic != null && user.piece_pic != null ?
                    "Votre compte est en cours de traitement." :
                    "Pour l'activer veuillez renseigner des informations sur votre profil"
            }
        </p>
        {
            user.selfie_pic && user.piece_pic && user.selfie_pic != null && user.piece_pic != null ?
                null :
                <Link to="/profil/update">
                    <Button props={{ color: 2, content: "Mettre a jour mon profil" }} />
                </Link>
        }

    </div>
}

export function OnStageMessage() {
    return <div className='accountNotActivated'>
        <h1>Vous avez soumis vos informations</h1>
        <p>
            Votre compte est en cours de traitement.
        </p>
    </div>
}
export default CD_Home
