import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router'
import Exchange_Historic_Layout from '../RouteComponents/exchanges/historic';
import { authUserSelector } from '../store/selectors/authSelectors';
import { NotActivatedMessage } from './ClientDashboard/home';
import UserExchange from './ClientDashboard/user-exchange';
function Exchange() {
    const params = useParams();
    const user = useSelector(authUserSelector)


    const exchangeComponents = {
        transactions: user.activated ? <UserExchange /> : <NotActivatedMessage user={user} />,
        historic: user.activated ? <Exchange_Historic_Layout user={user} /> : <NotActivatedMessage user={user} />
    }
    return (
        <div className='exchange'>
            {
                exchangeComponents[params.action]
            }
        </div>
    )
}




export default Exchange
