import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

function useCrypto() {
    const [cryptos, setCryptos] = useState([]);
    const [reload, setReload] = useState(false);


    async function getCryptos() {
        try {
            const req = await axios.get('/crypto')
            setCryptos(req.data.data)
        } catch (err) {
            console.log('crypto marche pas')
        }
    }

    useEffect(() => {
        getCryptos();
        reload && setReload(false);
    }, [reload])


    function reloadCryptos() {
        setReload(true)
    }


    return {
        cryptos, reload, reloadCryptos
    }
}

export default useCrypto;