import React from 'react'
import { useParams } from 'react-router';
import ClientTransactions from '../../RouteComponents/adminDashboard/clients/transactions';
import CryptoManager from '../../RouteComponents/exchanges/crypto';
import Agregators from './Agregators';
import ClientManager from '../../RouteComponents/adminDashboard/clients';

function Admin() {
    const { action, actionId } = useParams();
    const adminComponents = {
        crypto: <CryptoManager />,
        agregator: <Agregators />,
        clients: <ClientManager />
    }
    return (
        <div className='admin'>
            {
                actionId ? <>
                    <ClientTransactions clientId={actionId} />
                </> : <>
                    {adminComponents[action]}
                </>
            }
        </div>
    )
}
export default Admin;
