import { BiCurrencyExchange, Fa6SolidUser, FaDashboard, MaterialSymbolsHomeRounded, MdiAccountSupervisor, ZondiconsChart }
    from '../UiKIts/icons/nav'

export const navLinksArray = [
    {
        label: "Accueil", link: "/"
    },
    {
        label: "A propos", link: "#"
    },
    {
        label: "Echange", link: "#"
    }
]


export function sidebarAdminSidebarLinks(lang) {
    return [
        {
            label: lang.dashboardNav.adminMenu[0], link: "/", icon: <MaterialSymbolsHomeRounded />
        },
        {
            label: lang.dashboardNav.adminMenu[1], link: "/admin-dashboard", icon: <FaDashboard />
        },
        {
            label: lang.dashboardNav.adminMenu[2], link: "/admin/clients", icon: <MdiAccountSupervisor />
        },
        {
            label: lang.dashboardNav.adminMenu[3], link: "/admin/crypto", icon: <BiCurrencyExchange />
        },
        {
            label: lang.dashboardNav.adminMenu[4], link: "/exchange/historic", icon: <ZondiconsChart />
        },
        {
            label: lang.dashboardNav.adminMenu[5], link: "/profil/update", icon: <Fa6SolidUser />
        }
    ];
}


export function sidebarClientSidebarLinks(lang) {
    return [
        {
            label: lang.dashboardNav.adminMenu[0], link: "/", icon: <MaterialSymbolsHomeRounded />
        },
        {
            label: lang.dashboardNav.clientMenu[1], link: "/client-dashboard", icon: <FaDashboard />
        },
        { label: lang.dashboardNav.clientMenu[2], link: "/exchange/transactions", icon: <Fa6SolidUser /> },
        {
            label: lang.dashboardNav.clientMenu[3], link: "/exchange/historic", icon: <ZondiconsChart />
        },
        {
            label: lang.dashboardNav.clientMenu[4], link: "/profil/update", icon: <Fa6SolidUser />
        }
    ];
}
