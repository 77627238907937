import React from 'react';
import bitcoinImg from '../../Assets/images/visuels/echange/crypto.png';
import mtnImg from '../../Assets/images/visuels/echange/momo.png';
import carteImg from '../../Assets/images/visuels/echange/card.png';

function TauxEchanges({ lang }) {
  const imgsArray = [
    bitcoinImg, mtnImg, carteImg, carteImg
  ]
  return (
    <section className='taux'>
      <div className='taux-cards'>
        {
          lang.list.map((tr, index) => <div className='taux-card' key={"transaction list nb" + index}>
            <div className='card bg-bitcoin'>
              <img className='img-responsive' src={imgsArray[index]} alt='Image Mtn' />
            </div>
            <p className='text-muted'>{tr.title}</p>
            <p className='text-muted'>
              <span className='card-count'>{tr.count}</span> {lang.makedText}
            </p>
          </div>)
        }
      </div>
    </section>
  );
}

export default TauxEchanges;
