import { Link } from "react-router-dom";

export function ClientDetail({ data }) {

    return <ul className='customDataList'>
        <li className='cdl-full'>
            <Link to={"/admin/client/" + data._id + "/transactions"}>Voir les transactions</Link>
        </li>
        <li>
            <span>Nom :</span>{data.name}
        </li>
        <li>
            <span>Prénom :</span>{data.firstname}
        </li>
        <li>
            <span>Mail :</span>{data.email}
        </li>
        {
            data.selfie_pic && <li className='cdl-full'>
                <span>Photo Selfie:</span>
                <img src={data.selfie_pic} alt="user sefie pic desc" />
            </li>
        }
        {
            data.piece_pic && <li className='cdl-full'>
                <span>Photo de la piece :</span>{data.email}
                <img src={data.piece_pic} alt="user piece pic desc" />
            </li>
        }
    </ul>
}