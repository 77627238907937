import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import FormBtn from '../../UiKIts/form/btn'
import { Input } from '../../UiKIts/form/input'
import { InputPassword, InputPasswordConfirmation } from '../../UiKIts/form/password';

function Account_ChangePassword({ props }) {
    const { mailHasSet, mailToken } = props;
    const [formValues, setFormValues] = useState({ email: "", password: "", password_confirmation: "" });
    const [loader, setLoader] = useState(false);
    const [validField, setValidField] = useState(false);
    const [operationSuccess, setOperationSuccess] = useState(false)
    const [operationError, setOperationError] = useState(false)



    useEffect(() => {
        let cond = mailHasSet ? (formValues.password != "" && formValues.password_confirmation != "") : (formValues.email);
        if (cond)
            return setValidField(true)
        setValidField(false)
    }, [formValues])


    async function handleSubmit(e) {
        e.preventDefault();

        setLoader(true)
        try {
            if (!mailHasSet) {
                const makeReq = await axios.put('/auth/forgot', {
                    email: formValues.email
                })
                localStorage.setItem('sev-resetPwd-email', formValues.email)
            } else {
                const makeReq = await axios.put('/auth/reset/' + mailToken, {
                    email: localStorage.getItem('sev-resetPwd-email'),
                    password: formValues.password
                })
            }
            operationError && setOperationError('');
            mailHasSet ? setOperationSuccess("Le mot de passe a bien été changé !!!")
                : setOperationSuccess("Un mail de recuperation vous a été envoyé")
        } catch (error) {
            operationSuccess && setOperationError('')
            mailHasSet ? setOperationError("Erreur lors du changement de mot de passe")
                : setOperationError("Erreur avec votre email")
        }
        setLoader(false)
    }
    return (
        <div className='account-changePassword'>
            <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                {
                    !mailHasSet ? `Pour proceder au changement de votre mot de passe
                    veuillez entrez le mail avec lequel vous vous etes inscris!`
                        : `Definissez votre nouveau mot de passe`
                }
            </h3>
            <form action="" onSubmit={(e) => handleSubmit(e)}>
                {
                    !mailHasSet ? <Input props={{
                        name: "email", type: "email", ph: "Mon mail", label: "Mon mail", formValues, setFormValues
                    }} /> :
                        <>
                            <InputPassword props={{
                                name: "password", ph: "Nouveau mot de passe", label: "Nouveau mot de passe", formValues, setFormValues
                            }} />
                            <InputPasswordConfirmation props={{
                                name: "password_confirmation", ph: "Nouveau mot de passe", label: "Nouveau mot de passe",
                                pwdFieldValue: formValues.password, formValues, setFormValues
                            }} />
                        </>
                }
                <FormBtn props={{ content: "Soumettre", loader, btnIsActive: validField }} />
            </form>
            <br />
            {
                operationSuccess && <b className='formReqSuccess'>{operationSuccess}</b>
            }
            {
                operationError && <b className='formReqError'> {operationError}</b>
            }
        </div>
    )
}

export default Account_ChangePassword
