import React from 'react'
import { useParams } from 'react-router'
import AD_Home from './home';


function AdminDAshboard() {
    const params = useParams();


    function dispatchComponent() {
        if (params.action) {
            return "Client dash with action compo"
        }
        return <AD_Home />
    }
    return (
        <div className='adminDashboard'>
            {
                dispatchComponent()
            }
        </div>
    )
}

export default AdminDAshboard
