import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../UiKIts/button/button';
import { Link } from 'react-router-dom';
import { Input } from '../../UiKIts/form/input';
import { InputPassword, InputPasswordConfirmation } from '../../UiKIts/form/password';
import FormBtn from '../../UiKIts/form/btn';
import axios from 'axios';
import { AUthWithGoogleBtn } from './other';




function AuthSignUp({ lang }) {
  const [formValues, setFormValues] = useState({
    name: "", firstname: "", email: "", phone: "",
    password: "", confirm_password: ""
  });
  const [authError, setAuthError] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [validFields, setValidFields] = useState(false);


  const submitSignup = (e) => {
    e.preventDefault();

    axios.post("/auth/signup", { ...formValues })
      .then(res => setAuthSuccess('Votre inscription a bien été validé ,un mail de validation de compte vous a été envoyé.'))
      .catch(err => setAuthError("Une erreur s'est produite pendant l'inscription,veuillez ressayez"))
  };

  useEffect(() => {
    if (formValues.name != "" && formValues.firstname != "" &&
      formValues.email != "" && formValues.phone != "" && formValues.password != "") {
      return setValidFields(true)
    }
    setValidFields(false)
  }, [formValues])


  return (
    <div className='abtaf-form'>
      <AUthWithGoogleBtn lang={lang} />
      {
        authError || authSuccess && <h3 className={authError ? "formReqError" : "formReqSuccess"}>{authError || authSuccess}</h3>
      }
      <form method='POST' onSubmit={(e) => submitSignup(e)}>
        <Input
          props={{
            type: 'text', name: 'name',
            ph: lang.name.placeHolder, label: lang.name.label, withIcon: true,
            formValues, setFormValues,
          }}
        />
        <Input
          props={{
            type: 'text', name: 'firstname', ph: lang.firstname.placeHolder,
            label: lang.firstname.label,
            withIcon: true, formValues, setFormValues,
          }}
        />
        <Input
          props={{
            type: 'mail', name: 'email', ph: lang.mail.placeHolder,
            label: lang.mail.label, withIcon: true,
            formValues, setFormValues,
          }}
        />
        <Input
          props={{
            type: 'text', name: 'phone', ph: lang.phone.placeHolder,
            label: lang.phone.label, withIcon: true,
            formValues, setFormValues,
          }}
        />
        <InputPassword
          props={{
            ph: lang.passWord.placeHolder, name: "password", fieldVerification: "isLetterAndNumber", min: 8,
            label: lang.passWord.label,
            formValues, setFormValues,
          }}
        />
        <InputPasswordConfirmation
          props={{
            ph: lang.passWord2.placeHolder, name: "confirm_password", label: lang.passWord2.label,
            pwdFieldValue: formValues.password,
            formValues, setFormValues,
          }}
        />
        <FormBtn props={{ content: "S'inscrire", btnIsActive: validFields }} />

      </form>

      <p className='text-muted'>{lang.otherAuthText}</p>
      <Link to={'/auth/login'}>
        <Button
          props={{
            content: lang.otherAuthBtn, color: 1, noBackground: true,
          }}
        />
      </Link>

    </div>
  );
}

export default AuthSignUp;
