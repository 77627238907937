import { navLinksArray, sidebarAdminSidebarLinks, sidebarClientSidebarLinks } from "./rawDatas/links"
import { DashboardNav, SimpleNav } from "./UiKIts/navigation/navs"
import { Navigate } from "react-router";
import Sidebar from "./UiKIts/navigation/sidebar";
import Footer1 from "./UiKIts/footer/footer";
import { SimpleImage } from "./UiKIts/image/image";

function checkAdminOrClientMiddleware(user, requireAdmin, requireClient, location) {
    if (requireAdmin && user.role === "user" && location.includes('admin')) {
        return "/client-dashboard";
    }
    else if (requireClient && user.role === "admin" && (location === '/client-dashboard' || location === "/exchange/transactions")) {
        return "/admin-dashboard";
    }
    return true;
}
export function pageMiddleware(routeData, auth, child, sideBarOpen, location, user, logout, lang) {
    const pageWithNoMenu = ['/auth/login', '/auth/signup', '/uis'];
    const { requireAuth, requireAdmin, requireClient } = routeData;

    if (requireAuth == true) {
        if (auth) {
            if (user == null) {
                return <Loader />
            } else {
                return <>
                    {
                        checkAdminOrClientMiddleware(user, requireAdmin, requireClient, location.pathname) === true ? <>
                            <Sidebar props={{
                                sideLinks: user.role === "admin" ? sidebarAdminSidebarLinks(lang)
                                    : sidebarClientSidebarLinks(lang)
                            }} />
                            <main className={sideBarOpen ? 'mainToLeft' : 'dashboard'}>
                                <DashboardNav props={{ logoPath: 'userDefault.jpg', user, logout }} />
                                {child}
                            </main>
                        </> : <Navigate to={checkAdminOrClientMiddleware(user, requireAdmin, requireClient, location.pathname)} />
                    }
                </>
            }
        } else {
            return <Navigate to={"/auth/login"} />
        }
    }
    else if (requireAuth == "both") {
        return <>
            {
                ["/"].includes(location.pathname) && <SimpleNav props={{ navLinksArray, logoPath: 'logo_horizontal.png', auth }} />
            }
            {child}
            <Footer1 lang={lang.footer} />
        </>
    }

    return <>
        {
            (() => {
                if (auth) {
                    if (user == null) {
                        return <Loader />
                    } else {
                        return <>
                            <Navigate to={user.role === "user" ? "/client-dashboard" : "/admin-dashboard"} />
                            {/* {child} */}
                        </>
                    }
                } else {
                    return <>
                        {!pageWithNoMenu.includes(location.pathname) &&
                            <SimpleNav props={{ navLinksArray, logoPath: 'logo_shape.png' }} />
                        }
                        {child}
                        {!pageWithNoMenu.includes(location.pathname) &&
                            <>
                                <Footer1 lang={lang.footer} />
                            </>
                        }
                    </>
                }
            })()
        }

    </>
}

export function Loader() {
    return (
        <div className="loader">
            <SimpleImage props={{ src: "loader.gif", alt: "admin dash login" }} />
        </div>
    )
}
