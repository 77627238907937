import React from 'react';
import homeBanner from '../../Assets/images/banners/homeBanner.png';

function HowItWork() {
  return (
    <>
      <section id='howItWorks' className='how'>
        <h2>Comment ça marche ?</h2>
        <div className='how-body'>
          <div className='how-img'>
            <iframe
              title='Video Demo'
              allowFullScreen
              src='https://www.youtube.com/embed/tgbNymZ7vqY'
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowItWork;
