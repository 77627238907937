import React, { useState } from 'react';
import Button from '../../UiKIts/button/button';
import { Link } from 'react-router-dom';
import { Input } from '../../UiKIts/form/input';
import { InputPassword } from '../../UiKIts/form/password';
import FormBtn from '../../UiKIts/form/btn';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getTheUser, setAuth, setAuthToken } from '../../store/actions/authActions';
import { useEffect } from 'react';
import { AUthWithGoogleBtn } from './other';

function AuthLogin({ lang }) {
  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [authError, setAuthError] = useState(false);
  const [loader, setLoader] = useState(null);
  const [validFields, setValidFields] = useState(false);


  const dispatch = useDispatch();

  useEffect(() => {
    if (formValues.email != "" && formValues.password != "") {
      return setValidFields(true)
    }
    setValidFields(false)
  }, [formValues])

  const submitLogin = (e) => {
    e.preventDefault();
    setLoader(true)

    axios.post("/auth/signin", { ...formValues })
      .then(res => {
        dispatch(setAuth(true))
        dispatch(setAuthToken(res.data.data.token))
        dispatch(getTheUser());
        setLoader(false);
        axios.defaults.headers = {
          "x-auth-token": localStorage.getItem('auth-token')
        }
        // console.log(res.data.data)
      })
      .catch(err => {
        setAuthError('Mot de passe ou identifiant incorrect')
        setLoader(null)
      })
  };





  return (
    <div className='abtaf-form'>
      <AUthWithGoogleBtn lang={lang} />
      {
        authError && <h3 className='formReqError'>{authError}</h3>
      }
      <form onSubmit={(e) => submitLogin(e)}>
        <Input
          props={{
            type: 'mail', name: 'email', ph: 'Votre email', fieldVerification: "isMail",
            label: <>Email <span className='text-danger'>*</span></>,
            withIcon: true, formValues, setFormValues, setValidFields
          }}
        />
        <InputPassword
          props={{
            ph: 'Votre mot de passe', name: "password",
            label: <>Mot de passe<span className='text-danger'>*</span></>,
            formValues, setFormValues, setValidFields
          }}
        />

        <FormBtn props={{ content: lang.formBtn, loader, btnIsActive: validFields }} />

      </form>
      <Link to="/account/changePassword" className='text-primary'>{lang.forgetPasswordText} ?</Link>
      <p className='text-muted'>{lang.otherAuthText}</p>
      <Link to={'/auth/signup'}>
        <Button
          props={{
            content: lang.otherAuthBtn, color: 1, noBackground: true,
          }}
        />
      </Link>
    </div>
  );
}

export default AuthLogin;
